import React from "react";
import { useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { Link } from "react-router-dom";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import {BiLogOutCircle} from 'react-icons/bi'
import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined';
import OpacityIcon from '@mui/icons-material/Opacity';
import { useAuth0 } from "@auth0/auth0-react";
import {HiOutlineChartSquareBar} from 'react-icons/hi'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { HiDocumentReport } from "react-icons/hi";
import { FaChartLine } from "react-icons/fa6";
import { MdAnalytics } from "react-icons/md";
import { IoBarChartSharp } from "react-icons/io5";
import { FaDownload } from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";

const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    return (
      <MenuItem
        active={selected === title}
        // style={{ color: colors.grey[100], fontSize:'25px' }}
        onClick={() => setSelected(title)}
        icon={icon}
        routerLink={<Link to={to}/>}
      >
        <Typography style={{ fontSize: '16px', color: colors.grey[100], fontFamily:'Calibri' }}>{title}</Typography>      </MenuItem>
    );
  };


const Global_Sidebar=()=>{
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState("Dashboard");
    const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
    const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
    const { logout } = useAuth0();
    const font_side_bar = "25px"
    const justify_space ='15px'

    return(
        <Box justifyContent={'stretch'} paddingLeft={collapsed ? undefined : "10%"} >
         <Box paddingBottom={justify_space}> 
        <Item
          title="Emission Tracker"
          to="/app/dashboard"
          icon={<HomeOutlinedIcon style={{fontSize:"30px"}}/>}
          selected={selected}
          setSelected={setSelected}
        />
        <Item
          title="Data Display"
          to="/app/displaydashboard"
          icon={<HomeOutlinedIcon style={{fontSize:"30px"}}/>}
          selected={selected}
          setSelected={setSelected}
        />
         <Item
          title="SpendBased_Scope3"
          to="/app/SpendbasedScope3"
          icon={<HomeOutlinedIcon style={{fontSize:"30px"}}/>}
          selected={selected}
          setSelected={setSelected}
        />
        </Box>
        {/* <Box paddingBottom={justify_space}> 
        <Item
          title="Charts"
          to="/app/allcharts"
          icon={<IoBarChartSharp style={{fontSize:"25px"}}  />}
          selected={selected}
          setSelected={setSelected}
        />        </Box>
        <Box paddingBottom={justify_space}> 
         <Item
          title="Download"
          to="/app/download"
          icon={<FaDownload style={{fontSize:"25px"}}  />}
          selected={selected}
          setSelected={setSelected}
        /></Box>
        <Box paddingBottom={justify_space}> 
        <Item
          title="Analytics"
          to="/app/Analysis"
          icon={<MdAnalytics style={{fontSize:"30px"}} />}
          selected={selected}
          setSelected={setSelected}
        /></Box>
        <Box paddingBottom={justify_space}> 
        <Item
          title="Advanced Analytics"
          to="/app/Advanced_Analysis"
          icon={<IoMdAnalytics style={{fontSize:"25px"}} />}
          selected={selected}
          setSelected={setSelected}
        /></Box>
        <Box paddingBottom={justify_space}> 
        <Item
          title="Automated Reports"
          to="/app/Automated_Reports"
          icon={<HiDocumentReport style={{fontSize:"30px"}} />}
          selected={selected}
          setSelected={setSelected}
        /></Box> */}
        {/* <Item
          title="Humidity Chart"
          to="/app/Humdchart"
          icon={<OpacityIcon style={{fontSize:"20px"}} />}
          selected={selected}
          setSelected={setSelected}
        /> */}
        {/* adding the logout clicking functionality to the item logout */}
        <Box paddingBottom={justify_space}> 
        <div onClick={() => logout()}> 
        <Item
          title="Logout"
          icon={<BiLogOutCircle style={{ fontSize: "25px" }} />}
          selected={selected}
          setSelected={setSelected}
        />
        </div></Box>
        </Box>
    )
}
export default Global_Sidebar;