import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { ResponsiveBar } from '@nivo/bar';
// import { Grid, Box, Typography, Button, Autocomplete, TextField } from '@mui/material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { SourceContext } from '../contexts/Sourcecontext';
import ExcelUploader from './Exceluploader';

import { Box, Button, Table, TableHead, TableBody, TableRow, TableCell, Collapse, Typography } from '@mui/material';
import * as XLSX from 'xlsx';

const Spendbased_scope3 = () => {
    const [data, setData] = useState([]);
    const [expandedCategory, setExpandedCategory] = useState(null);
  
    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
  
        // Summing up the categories
        const categoryTotals = {};
        jsonData.forEach((entry) => {
          const { category, amount } = entry;
          categoryTotals[category] = (categoryTotals[category] || 0) + Number(amount);
        });
  
        // Sorting categories by total spend
        const sortedData = Object.entries(categoryTotals)
          .sort(([, totalA], [, totalB]) => totalB - totalA)
          .map(([category, total]) => ({
            category,
            total,
            entries: jsonData.filter((entry) => entry.category === category),
          }));
  
        setData(sortedData);
      };
  
      reader.readAsBinaryString(file);
    };
  

    return (
        <Box
          sx={{
            minHeight: '100vh', // Ensures it still covers the full viewport height
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: '#f5f5f5', // Light background
            boxShadow: 3,
            p: 6,
            borderRadius: 2,
            overflow: 'auto' // Allows scrolling if content grows too big
          }}
        >
          <Box
            sx={{
              maxWidth: 1200, // Adjust for more width
              width: '100%',
              bgcolor: 'white',
              p: 6,
              boxShadow: 4,
              borderRadius: 2,
            }}
          >
            {/* Title */}
            <Typography
              variant="h3"
              align="center"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                mb: 4,
              }}
            >
              Spend Based Scope 3 Analysis
            </Typography>
    
            {/* Excel Uploader */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
              <Button 
                variant="contained" 
                component="label" 
                sx={{ bgcolor: '#1976d2', color: 'white', boxShadow: 3, '&:hover': { bgcolor: '#1565c0' } }}
              >
                Upload Excel File
                <input type="file" hidden accept=".xlsx, .xls" onChange={handleFileUpload} />
              </Button>
            </Box>
    
            {/* Data Table (Displayed after Excel upload) */}
            {data.length > 0 && (
              <Box sx={{ mt: 4, maxHeight: '60vh', overflowY: 'auto' }}> {/* Limit table height with scroll */}
                <Table sx={{ width: '100%', tableLayout: 'fixed', bgcolor: '#fafafa', boxShadow: 2 }}>
                  <TableHead>
                    <TableRow sx={{ bgcolor: '#f0f0f0' }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>Category</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Total Spend</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((item, index) => (
                      <React.Fragment key={index}>
                        <TableRow
                          onClick={() => setExpandedCategory(expandedCategory === index ? null : index)}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': { bgcolor: '#f5f5f5' },
                            borderBottom: '1px solid #e0e0e0'
                          }}
                        >
                          <TableCell sx={{ padding: '12px' }}>{item.category}</TableCell>
                          <TableCell sx={{ padding: '12px' }}>{item.total}</TableCell>
                        </TableRow>
                        
                        {/* Collapsible Row */}
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Collapse in={expandedCategory === index} timeout="auto" unmountOnExit>
                              <Table size="small" sx={{ bgcolor: '#fff', mt: 2, boxShadow: 1 }}>
                                <TableHead>
                                  <TableRow sx={{ bgcolor: '#e0e0e0' }}>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Supplier</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Currency</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {item.entries.map((entry, entryIndex) => (
                                    <TableRow key={entryIndex} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                                      <TableCell sx={{ padding: '8px' }}>{entry.date}</TableCell>
                                      <TableCell sx={{ padding: '8px' }}>{entry.supplier}</TableCell>
                                      <TableCell sx={{ padding: '8px' }}>{entry.amount}</TableCell>
                                      <TableCell sx={{ padding: '8px' }}>{entry.currency}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Box>
        </Box>
      );
    };
export default Spendbased_scope3;