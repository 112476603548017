import React, { createContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
const SourceContext = createContext();


const SourceProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  
  const [parentCompany, setParentCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
 
  const [equity, setEquity] = useState('');
  const [operationalPercentage, setOperationalPercentage] = useState('');
  const [financialPercentage, setFinancialPercentage] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [superStatcombusstate, setsuperStatcombusstate] = useState({}); // This will store data for all stationary combustion from the database
  const [superStatmobilecomb, setsuperStatmobilecomb] = useState({}); // This will store data for all stationary combustion from the database


  // const [facilities, setFacilities] = useState([{}]);
  const [facilities, setFacilities] = useState([{
    id: " ",
    data: {
      facilityDescription: '',
      facilityArea: '',
      fuels: [{ fuelCombusted: '', quantityCombusted: '', units: '', heatingvalue: '' }],
    }
  }]);

   // const [facilities, setFacilities] = useState([{}]);
   const [fleets, setFleets] = useState([{
    id: 1,
    data: {
      fleetDescription: '',
      vehicles: [{ vehicleType: '', vehicleSubcategory: '',vehicleYear: '', vehicleId :'',vehicleFuel :'',fuelQuantity:'',units: '', distanceTravelled:'' }]
    }
  }]);

   // const [facilities, setFacilities] = useState([{}]);
   const [fugitivefacilities, setfugitivefacilities] = useState([{
    id: 1,
    data: {
      fugitivefacilityDescription: '',
      fugitivefacilityArea: '',
      fugitivegases: [{ gasname: '',initialgas: '',finalgas: '', gaspurchased:'', gassold:'', capacitychange:'' }],
    }
  }]);

  const [elecfacilities, setElecFacilities] = useState([{
    id: 1,
      data: {
        ElecfacilityDescription: '',
        ElecfacilityArea: '',
        Electricity: [{ city:'', KWh: '', emFactor: ''}],
        RenewableElec :[{ renewableCity:'', renewableKWh: '', renewableEmFactor: ''}],
      }
  }]);

  const [SHCfacilities, setSHCFacilities] = useState([{
    id: 1,
      data: {
        SHCfacilityDescription: '',
        SHCfacilityArea: '',
        steamsources: [{ fuelCombusted: '', boilerefficiency: '', steampurhased: '' }],
        coolingElec: '',
        coolingEff: '',
        heatingElec: '',
        heatingEff: ''
      }
  }]);


  // const [sourceSelection, setSourceSelection] = useState(() => {
  //   const storedSourceSelection = JSON.parse(localStorage.getItem('sourceSelection'));
  //   return storedSourceSelection || {};
  // });
  const [reportingPeriods, setReportingPeriods] = useState([]);

  const handlereportingperiods = (data) => {
    // Extract all reporting periods from the data
    const periods = data.map(record => record.reporting_period);
    
    // Remove duplicates by converting the array to a Set, then back to an array
    const uniquePeriods = [...new Set(periods)];
    
    // Log the unique extracted periods
    // console.log('Unique Reporting Periods:', uniquePeriods);
    
    // Set the unique periods into the state
    setReportingPeriods(uniquePeriods);
};
  

  const groupSubsidiariesByReportingPeriod = (data) => {
    // Initialize an empty object to store the grouped subsidiaries
    const groupedSubsidiaries = {};
  
    // Iterate over each record in the data
    data.forEach(record => {
      const { reporting_period, subsidiary_name, equity_percentage, financial_percentage, operational_percentage } = record;
  
      // If the reporting period isn't already a key in the object, add it
      if (!groupedSubsidiaries[reporting_period]) {
        groupedSubsidiaries[reporting_period] = [];
      }
  
      // Add the subsidiary details to the array for the corresponding reporting period
      groupedSubsidiaries[reporting_period].push({
        subsidiary_name,
        equity_percentage,
        financial_percentage,
        operational_percentage
      });
    });
  
    // Log the grouped subsidiaries for verification
    // console.log('Grouped Subsidiaries by Reporting Period:', groupedSubsidiaries);
  
    // You can now set this object to state if needed, e.g., setSubsidiaries(groupedSubsidiaries);
    setCompanies(groupedSubsidiaries);
  };  

// Initialize sourceSelection as an empty object
const [sourceSelection, setSourceSelection] = useState({});

// Function to process and update sourceSelection
const processAndSetSourceSelection = (data) => {
  const sourcesBySubsidiary = data.reduce((acc, record) => {
    const periodSubsidiaryKey = `${record.reporting_period}-${record.subsidiary_name}`;
    const sources = JSON.parse(record.source.replace(/'/g, '"')); // Convert source string to an array

    if (!acc[periodSubsidiaryKey]) {
      acc[periodSubsidiaryKey] = new Set();
    }

    // Merge sources, avoiding duplicates using Set
    sources.forEach(source => acc[periodSubsidiaryKey].add(source));

    return acc;
  }, {});

  // Convert the sets back to arrays for easier usage in your application
  const formattedSources = Object.fromEntries(
    Object.entries(sourcesBySubsidiary).map(([key, value]) => [key, Array.from(value)])
  );

  // Update the sourceSelection state
  setSourceSelection(formattedSources);
  // console.log('Updated sourceSelection:', formattedSources);
};

  
  useEffect(() => {
    const fetchParentCompany = async () => {
      try {
        const token = await getAccessTokenSilently();
        // console.log(token)
        const response = await axios.get('http://localhost:5000/api/toptabledata',{
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = response.data;
        // console.log("data",data)

        const records = Array.isArray(data) ? data : [data];
    
      if (data.length > 0) {
        const firstRecord = records[0];
        setParentCompany({
          name: firstRecord.parent_company,
          country: firstRecord.parent_company_country,
          state: firstRecord.parent_company_state
        });
       
        // console.log('d.rp',data); // This logs the array of periods
        handlereportingperiods(data);       
        groupSubsidiariesByReportingPeriod(data) 
        processAndSetSourceSelection(data);
      }
    } catch (error) {
      console.error('Error fetching parent company:', error);
    }
    };

fetchParentCompany();
}, [getAccessTokenSilently]);

/////////////////////////////////////
useEffect(() => {
  const addnewcompany = async () => {
    try {
      const token = await  getAccessTokenSilently();
      
      const response = await axios.post('http://localhost:5000/api/addsubsidiary',{ 
        // username: user.name,
        parentCompany:parentCompany.name,
        parentCompanycountry:parentCompany.country,
        parentCompanystate:parentCompany.state,
        reportingPeriods:reportingPeriods,
        companies:companies,
       } , {
        headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  }
   catch (error) {
    console.error('Error adding new company:', error);
  }
  };

  addnewcompany();
}, [companies]);
  // const [reportingPeriods, setReportingPeriods] = useState(() => {
  //   const storedPeriods = JSON.parse(localStorage.getItem('reportingPeriods'));
  //   return storedPeriods || [];
  // });
  const [monthlyData, setMonthlyData] = useState(() => {
    const storedData = JSON.parse(localStorage.getItem('monthlyData'));
    return storedData || {};
  });

  const addParentCompany = (company) => {
    setParentCompany(company);
    // localStorage.setItem('parentCompany', JSON.stringify(company));
  };

  const deleteParentCompany = () => {
    setParentCompany(null);
    // localStorage.removeItem('parentCompany');
    // Clear related data if parent company is deleted
    setCompanies({});
    setSourceSelection({});
    setReportingPeriods([]);
    setMonthlyData({});
    localStorage.removeItem('companies');
    localStorage.removeItem('sourceSelection');
    localStorage.removeItem('reportingPeriods');
    localStorage.removeItem('monthlyData');
  };


  const addCompany = (reportingPeriod, company) => {
    setCompanies(prevCompanies => {
      // Create a copy of the existing companies object
      const updatedCompanies = { ...prevCompanies };

      // Check if the reporting period already exists
      if (!updatedCompanies[reportingPeriod]) {
        updatedCompanies[reportingPeriod] = []; // Initialize the array if it doesn't exist
      }

      // Add the new company to the reporting period's array
      updatedCompanies[reportingPeriod].push(company);

      // Return the updated companies object
      return updatedCompanies;
    });
  };



  const deleteCompany = (reportingPeriod, companyName) => {
    const updatedCompanies = {
      ...companies,
      [reportingPeriod]: companies[reportingPeriod].filter(
        (company) => company.name !== companyName
      ),
    };
    setCompanies(updatedCompanies);
    localStorage.setItem('companies', JSON.stringify(updatedCompanies));
  };

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
  };

  const handleSourceSelect = (reportingPeriod, company, source) => {
    const periodKey = `${reportingPeriod}-${company.subsidiary_name}`;
    const companySources = sourceSelection[periodKey] || [];
    const updatedSources = companySources.includes(source)
      ? companySources.filter((s) => s !== source)
      : [...companySources, source];

    const newSourceSelection = {
      ...sourceSelection,
      [periodKey]: updatedSources,
    };

    setSourceSelection(newSourceSelection);
    // localStorage.setItem('sourceSelection', JSON.stringify(newSourceSelection));
  };

  const addReportingPeriod = (period) => {
    if (!reportingPeriods.includes(period)) {
      const updatedPeriods = [...reportingPeriods, period];
      setReportingPeriods(updatedPeriods);
      localStorage.setItem('reportingPeriods', JSON.stringify(updatedPeriods));
    }
  };

  const deleteReportingPeriod = (period) => {
    const updatedPeriods = reportingPeriods.filter((p) => p !== period);
    setReportingPeriods(updatedPeriods);
    localStorage.setItem('reportingPeriods', JSON.stringify(updatedPeriods));
  };

  const handleMonthlyDataEntry = (reportingPeriod, company, source, month, data) => {
    const periodKey = `${reportingPeriod}-${company.subsidiary_name}`;
    const sourceKey = `${periodKey}-${source}`;
    const updatedMonthlyData = {
      ...monthlyData,
      [sourceKey]: {
        ...monthlyData[sourceKey],
        [month]: data,
      },
    };

    setMonthlyData(updatedMonthlyData);
    localStorage.setItem('monthlyData', JSON.stringify(updatedMonthlyData));
  };

  useEffect(() => {
    if (selectedCompany && !sourceSelection[selectedCompany.subsidiary_name]) {
      setSourceSelection({
        ...sourceSelection,
        [selectedCompany.subsidiary_name]: [],
      });
    }
  }, [selectedCompany, sourceSelection]);
  const [separatedFacilitiesT, setSeparatedFacilitiesT] = useState({});
  const [separatedFleetsT, setSeparatedFleetsT] = useState({});


  ///////////////////////

//   const fetchAndProcessData_stationarycombustion = async () => {
//     try {
//       const token = await getAccessTokenSilently();
//       const response = await axios.get('http://localhost:5000/api/fetchstationarycombustion', {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         // params: {
//         //   company,
//         //   month,
//         //   reportingPeriod,
//         // }
//       });

//       const separatedFacilities = {};
//       response.data.facilities.forEach((facility, index) => {
//         const key = `stationarycombustion_${facility.data.company}_${facility.data.month}_${facility.data.period}`;
//         separatedFacilities[key] = separatedFacilities[key] || [];
//         separatedFacilities[key].push({
//           id: facility.id || index + 1,
//           data: {
//             facilityDescription: facility.data?.facilityDescription || '',
//             facilityArea: facility.data?.facilityArea || '',
//             fuels: facility.data?.fuels || [{ 
//               fuelCombusted: '', 
//               quantityCombusted: '', 
//               units: '', 
//               heatingvalue: '' 
//             }],
//             emissions: facility.data?.emissions || [{ 
//               co2: '', 
//               n2o: '', 
//               ch4: '', 
//               eqco2: '' 
//             }],
//           },
//         });
//       });

//       setsuperStatcombusstate(prevState => ({
//         ...prevState,
//         ...separatedFacilities,
//       }));

//       setSeparatedFacilitiesT(separatedFacilities);
//     } catch (error) {
//       console.error('Error fetching facilities:', error);
//     }
//   };

//   ////////////////////////

// ///////////////////////

// const fetchAndProcessData_mobilecombustion = async () => {
//   try {
//     const token = await getAccessTokenSilently();
//     const response = await axios.get('http://localhost:5000/api/fetchmobilecombustion', {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       // params: {
//       //   company,
//       //   month,
//       //   reportingPeriod,
//       // }
//     });

//     const separatedFleets = {};
//     response.data.facilities.forEach((fleet, index) => {
//       const key = `mobilecombustion_${fleet.data.company}_${fleet.data.month}_${fleet.data.period}`;
//       separatedFleets[key] = separatedFleets[key] || [];
//       separatedFleets[key].push({
//         id: fleet.id || index + 1,
//         data: {
//           fleetDescription: fleet.data?.fleetDescription || '',
//           vehicles: fleet.data?.vehicles || [{ 
//             vehicleType: '', 
//             vehicleSubcategory: '',
//             vehicleYear: '', 
//             vehicleId :'',
//             vehicleFuel :'',
//             fuelQuantity:'',
//             units: '', 
//             distanceTravelled:''
//           }],
//           emissions: fleet.data?.emissions || [{ 
//             co2: '', 
//             n2o: '', 
//             ch4: '', 
//             eqco2: '' 
//           }],
//         },
//       });
//     });

//     setsuperStatmobilecomb(prevState => ({
//       ...prevState,
//       ...separatedFleets,
//     }));
//     setSeparatedFleetsT(separatedFleets);
//     console.log("separatedFleets",separatedFleets)
//   } catch (error) {
//     console.error('Error fetching facilities:', error);
//   }
// };

const  fetchAndProcessData = async () => {
  try {
    // Get the token once
    const token = await getAccessTokenSilently();

    // Fetch stationary combustion data
    const responseStationary = await axios.get('http://localhost:5000/api/fetchstationarycombustion', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(responseStationary)
    const separatedFacilities = {};
    responseStationary.data.facilities.forEach((facility, index) => {
      const key = `stationarycombustion_${facility.data.company}_${facility.data.month}_${facility.data.period}`;
      separatedFacilities[key] = separatedFacilities[key] || [];
      separatedFacilities[key].push({
        id: facility.id || index + 1,
        data: {
          facilityDescription: facility.data?.facilityDescription || '',
          facilityArea: facility.data?.facilityArea || '',
          fuels: facility.data?.fuels || [{ 
            fuelCombusted: '', 
            quantityCombusted: '', 
            units: '', 
            heatingvalue: '' 
          }],
          emissions: facility.data?.emissions || [{ 
            co2: '', 
            n2o: '', 
            ch4: '', 
            eqco2: '' 
          }],
        },
      });
    });

    setsuperStatcombusstate(prevState => ({
      ...prevState,
      ...separatedFacilities,
    }));
    setSeparatedFacilitiesT(separatedFacilities);

    // Fetch mobile combustion data
    const responseMobile = await axios.get('http://localhost:5000/api/fetchmobilecombustion', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
      console.log(responseMobile)
    const separatedFleets = {};
    responseMobile.data.fleets.forEach((fleet, index) => {
      const key = `mobilesources_${fleet.data.company}_${fleet.data.month}_${fleet.data.period}`;
      separatedFleets[key] = separatedFleets[key] || [];
      separatedFleets[key].push({
        id: fleet.id || index + 1,
        data: {
          fleetDescription: fleet.data?.fleetDescription || '',
          vehicles: fleet.data?.vehicles || [{ 
            vehicleType: '', 
            vehicleSubcategory: '',
            vehicleYear: '', 
            vehicleId :'',
            vehicleFuel :'',
            fuelQuantity:'',
            units: '', 
            distanceTravelled:''
          }],
          emissions: fleet.data?.emissions || [{ 
            co2: '', 
            n2o: '', 
            ch4: '', 
            eqco2: '' 
          }],
        },
      });
    });

    setsuperStatmobilecomb(prevState => ({
      ...prevState,
      ...separatedFleets,
    }));
    console.log(separatedFleets)
    setSeparatedFleetsT(separatedFleets);
    console.log("separatedFleets",separatedFleets);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

// Call the function
// fetchAndProcessData_all();








  ////////////////////////////////
  return (
    <SourceContext.Provider
      value={{
        parentCompany,
        companies,
        selectedCompany,
        sourceSelection,
        reportingPeriods,
        addParentCompany,
        deleteParentCompany,
        addCompany,
        deleteCompany,
        handleCompanySelect,
        handleSourceSelect,
        addReportingPeriod,
        deleteReportingPeriod,
        handleMonthlyDataEntry,
        monthlyData,
        equity,
        setEquity,
        operationalPercentage,
        setOperationalPercentage,
        financialPercentage,
        setFinancialPercentage,
        facilities,
        setFacilities,
        superStatcombusstate,
        setsuperStatcombusstate,
        separatedFacilitiesT,
        // fetchAndProcessData_stationarycombustion,
        fleets,
        setFleets,
        superStatmobilecomb,
        setsuperStatmobilecomb,
        separatedFleetsT,
        // fetchAndProcessData_mobilecombustion,
        fetchAndProcessData,
        fugitivefacilities,
        setfugitivefacilities,
        elecfacilities,
        setElecFacilities,
        SHCfacilities,
        setSHCFacilities
      }}
    >
      {children}
    </SourceContext.Provider>
  );
};

export { SourceProvider, SourceContext };







// import React, { createContext, useState, useEffect } from 'react';
// import axios from 'axios';
// import { useAuth0 } from '@auth0/auth0-react';

// const SourceContext = createContext();

// const SourceProvider = ({ children }) => {
//   const { getAccessTokenSilently, user } = useAuth0(); // Getting the access token and user info

//   const [parentCompany, setParentCompany] = useState(null);
//   const [companies, setCompanies] = useState({});
//   const [selectedCompany, setSelectedCompany] = useState(null);
//   const [sourceSelection, setSourceSelection] = useState({});
//   const [reportingPeriods, setReportingPeriods] = useState([]);
//   const [monthlyData, setMonthlyData] = useState({});

//   // Function to fetch all user data from the API
//   const fetchData = async () => {
//     try {
//       const token = await getAccessTokenSilently();
      
//       const response = await axios.get(`/api/userData?userId=${user.sub}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });

//       const data = response.data;
//       setParentCompany(data.parentCompany || null);
//       setCompanies(data.companies || {});
//       setSourceSelection(data.sourceSelection || {});
//       setReportingPeriods(data.reportingPeriods || []);
//       setMonthlyData(data.monthlyData || {});
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   // Function to save data to the database
//   const saveData = async (data) => {
//     try {
//       const token = await getAccessTokenSilently();

//       await axios.post(`/api/userData?userId=${user.sub}`, data, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//     } catch (error) {
//       console.error('Error saving data:', error);
//     }
//   };

//   // UseEffect to fetch data when the component mounts
//   useEffect(() => {
//     fetchData();
//   }, [getAccessTokenSilently, user]);

//   const addParentCompany = (company) => {
//     const updatedData = { ...parentCompany, company };
//     setParentCompany(company);
//     saveData({ ...updatedData });
//   };

//   const deleteParentCompany = () => {
//     const updatedData = {
//       parentCompany: null,
//       companies: {},
//       sourceSelection: {},
//       reportingPeriods: [],
//       monthlyData: {},
//     };

//     setParentCompany(null);
//     setCompanies({});
//     setSourceSelection({});
//     setReportingPeriods([]);
//     setMonthlyData({});
//     saveData(updatedData);
//   };

//   const addCompany = (reportingPeriod, company) => {
//     const updatedCompanies = { ...companies };
//     if (!updatedCompanies[reportingPeriod]) {
//       updatedCompanies[reportingPeriod] = [];
//     }
//     updatedCompanies[reportingPeriod].push(company);
//     setCompanies(updatedCompanies);
//     saveData({ companies: updatedCompanies });
//   };

//   const deleteCompany = (reportingPeriod, companyName) => {
//     const updatedCompanies = {
//       ...companies,
//       [reportingPeriod]: companies[reportingPeriod].filter(
//         (company) => company.name !== companyName
//       ),
//     };
//     setCompanies(updatedCompanies);
//     saveData({ companies: updatedCompanies });
//   };

//   const handleCompanySelect = (company) => {
//     setSelectedCompany(company);
//   };

//   const handleSourceSelect = (reportingPeriod, company, source) => {
//     const periodKey = `${reportingPeriod}-${company.name}`;
//     const companySources = sourceSelection[periodKey] || [];
//     const updatedSources = companySources.includes(source)
//       ? companySources.filter((s) => s !== source)
//       : [...companySources, source];

//     const newSourceSelection = {
//       ...sourceSelection,
//       [periodKey]: updatedSources,
//     };

//     setSourceSelection(newSourceSelection);
//     saveData({ sourceSelection: newSourceSelection });
//   };

//   const addReportingPeriod = (period) => {
//     if (!reportingPeriods.includes(period)) {
//       const updatedPeriods = [...reportingPeriods, period];
//       setReportingPeriods(updatedPeriods);
//       saveData({ reportingPeriods: updatedPeriods });
//     }
//   };

//   const deleteReportingPeriod = (period) => {
//     const updatedPeriods = reportingPeriods.filter((p) => p !== period);
//     setReportingPeriods(updatedPeriods);
//     saveData({ reportingPeriods: updatedPeriods });
//   };

//   const handleMonthlyDataEntry = (reportingPeriod, company, source, month, data) => {
//     const periodKey = `${reportingPeriod}-${company.name}`;
//     const sourceKey = `${periodKey}-${source}`;
//     const updatedMonthlyData = {
//       ...monthlyData,
//       [sourceKey]: {
//         ...monthlyData[sourceKey],
//         [month]: data,
//       },
//     };

//     setMonthlyData(updatedMonthlyData);
//     saveData({ monthlyData: updatedMonthlyData });
//   };

//   useEffect(() => {
//     if (selectedCompany && !sourceSelection[selectedCompany.name]) {
//       setSourceSelection({
//         ...sourceSelection,
//         [selectedCompany.name]: [],
//       });
//     }
//   }, [selectedCompany, sourceSelection]);

//   return (
//     <SourceContext.Provider
//       value={{
//         parentCompany,
//         companies,
//         selectedCompany,
//         sourceSelection,
//         reportingPeriods,
//         addParentCompany,
//         deleteParentCompany,
//         addCompany,
//         deleteCompany,
//         handleCompanySelect,
//         handleSourceSelect,
//         addReportingPeriod,
//         deleteReportingPeriod,
//         handleMonthlyDataEntry,
//         monthlyData,
//       }}
//     >
//       {children}
//     </SourceContext.Provider>
//   );
// };

// export { SourceProvider, SourceContext };

