
import React from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';

const CompanySourcesTable = ({ reportingPeriod, companies, sourceSelection }) => {
  const periodCompanies = companies[reportingPeriod] || [];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company Name</TableCell>
            <TableCell>Selected Sources</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {periodCompanies.map((company) => {
            const periodKey = `${reportingPeriod}-${company.subsidiary_name}`;
            return (  
              <TableRow key={company.subsidiary_name}>
                <TableCell>{company.subsidiary_name}</TableCell>
                <TableCell>
                  {sourceSelection[periodKey] ? sourceSelection[periodKey].join(', ') : 'None'}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompanySourcesTable;
