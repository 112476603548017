import React from "react"
import ReactLoading from 'react-loading';
const Loading_animation= () => {
return(
    <div style={{display: 'flex',flexDirection: "column", 
    justifyContent:'center', alignItems:'center', height:"100vh", margin:"0"}} >
        <img src={"https://user-images.githubusercontent.com/74341306/222716602-3c8bcf6c-cd64-49df-9f1b-72df89d0b33e.png"}
         width="120px"
         height="140px" />
         
        <ReactLoading type={"bars"} color={"grey"} width="100px"
         height="120px"  />
         <div style={{fontSize:'25px'}}>LOADING...</div>
    </div>
)
}
export default Loading_animation
