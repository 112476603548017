import React, { useState, useEffect, useRef } from 'react';
import { GrCircleInformation } from "react-icons/gr";

const InformationButton = ({ informationText }) => {
    const [isHovered, setIsHovered] = useState(false);
  
    return (
      <div style={{ position:'absolute', display: 'inline-flex', alignItems: 'center'}}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <GrCircleInformation style={{ fontSize: "25px" }}/>
               {isHovered && <div style={{position:'relative', boxShadow: '0 3px 6px rgba(1, 1, 1, 1)', width: 'auto'}}
               >{informationText}</div>}
      </div>
    );
  };
  export default InformationButton;