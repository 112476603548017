import React, { useState, useEffect, useContext } from 'react';
import {
    TableContainer,Paper, Grid, FormControlLabel,Drawer, Box, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, IconButton, Tabs, Tab, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
// import Switch from '@mui/material/Switch';
import { Switch } from 'antd';
import { ArrowDownward, Delete } from '@mui/icons-material';
import { SourceContext } from '../contexts/Sourcecontext';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { convertLegacyOperators } from '@mui/x-data-grid/internals';
import statcombution_logo from './stationary_combustion.png'

const PurchasedElectricityDialog = ({ open, onClose, onSubmit, source, month, year, company, reportingPeriod }) => {
    const initialFormData = {
      ElecelecfacilitiesDescription: '',
      ElecelecfacilitiesArea: '',
      Electricity: [{ city: '', KWh: '', emFactor: '' }],
      RenewableElec :[{ renewableCity:'', renewableKWh: '', renewableEmFactor: ''}],
    };
  
    const { parentCompany, elecfacilities = [], setElecFacilities } = useContext(SourceContext);
    const { superStatcombusstate, separatedFacilitiesT, fetchAndProcessData } = useContext(SourceContext);
    const [currentTab, setCurrentTab] = useState(0);
    const { getAccessTokenSilently } = useAuth0();
    const [dialogData, setDialogData] = useState(null);
    const [showRenewableElecForm, setShowRenewableElecForm] = useState(false);

  
    const handleTabChange = (event, newValue) => {
      setCurrentTab(newValue);
    };
  
    useEffect(() => {
      if (open) {
        fetchAndProcessData();
      }
    }, [open]);
  
    useEffect(() => {
      const key = `purchasedelectricity_${company}_${month}_${reportingPeriod}`;
      setDialogData(separatedFacilitiesT[key] || []);
    }, [separatedFacilitiesT, company, month, reportingPeriod]);
  
    const handleChange = (e, elecfacilitiesIndex, elecIndex = null, renewable = false) => {
      const { name, value } = e.target;
      const updatedFacilities = elecfacilities.map((elecfacilities, index) => {
        if (index === elecfacilitiesIndex) {
          if (elecIndex !== null) {
            const updatedElec = renewable
              ? elecfacilities.data.RenewableElec.map((elec, idx) => {
                  if (idx === elecIndex) {
                    return { ...elec, [name]: value };
                  }
                  return elec;
                })
              : elecfacilities.data.Electricity.map((elec, idx) => {
                  if (idx === elecIndex) {
                    return { ...elec, [name]: value };
                  }
                  return elec;
                });
            return renewable
              ? { ...elecfacilities, data: { ...elecfacilities.data, RenewableElec: updatedElec } }
              : { ...elecfacilities, data: { ...elecfacilities.data, Electricity: updatedElec } };
          }
          return { ...elecfacilities, data: { ...elecfacilities.data, [name]: value } };
        }
        return elecfacilities;
      });
      setElecFacilities(updatedFacilities);
    };
  
    const addRenewableElec = () => {
        setShowRenewableElecForm(true); // Show the renewable electricity form
      const updatedFacilities = [...elecfacilities];
      updatedFacilities[currentTab].data.RenewableElec.push({ city: '', KWh: '', emFactor: '' });
      setElecFacilities(updatedFacilities);
    };

    const addFacility = () => {
        setElecFacilities([
          ...elecfacilities,
          {
            id: elecfacilities.length + 1,
            data: initialFormData
          }
        ]);
        setCurrentTab(elecfacilities.length); // Move to the newly added facility tab
      };
  
      const deleteFacility = (facilityId) => {
        const updatedFacilities = elecfacilities.filter(facility => facility.id !== facilityId);
        setElecFacilities(updatedFacilities);
        if (currentTab >= updatedFacilities.length) {
          setCurrentTab(updatedFacilities.length - 1);
        }
      };
      const handleRenewableElecChange = (e, field) => {
        const newRenewableElec = {
          ...elecfacilities[currentTab].data.RenewableElec,
          [field]: e.target.value,
        };
      
        setElecFacilities((prev) => ({
          ...prev,
          [currentTab]: {
            ...prev[currentTab],
            data: {
              ...prev[currentTab].data,
              RenewableElec: newRenewableElec,
            },
          },
        }));
      };
  
    const [showEmissionFactor, setShowEmissionFactor] = useState(false); // State for toggle switch

//   const handleSwitchChange = (event) => {
//     setShowEmissionFactor(event.target.checked); // Update state based on switch
//   };
        const handleSwitchChange = (checked) => {
            setShowEmissionFactor(checked); // Update state based on the checked value
        };

      const statesByCountry = {
        India: [
          'Maharashtra', 'Karnataka', 'Tamil Nadu', 'Gujarat', 'Rajasthan',
          'Uttar Pradesh', 'West Bengal', 'Delhi', 'Punjab', 'Haryana',
          'Bihar', 'Assam', 'Odisha', 'Kerala', 'Jharkhand',
          'Chhattisgarh', 'Himachal Pradesh', 'Uttarakhand', 'Tripura', 'Meghalaya',
          'Manipur', 'Nagaland', 'Arunachal Pradesh', 'Sikkim', 'Goa',
          'Puducherry', 'Andaman and Nicobar Islands', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep',
        ],
        Australia: [
          'New South Wales', 'Victoria', 'Queensland', 'South Australia', 'Western Australia',
          'Tasmania', 'Australian Capital Territory', 'Northern Territory',
        ],
        USA: [
          'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
          'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
          'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
          'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
          'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
          'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
          'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
          'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
          'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
          'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
        ],
        NewZealand: [
          'Auckland', 'Bay of Plenty', 'Canterbury', 'Gisborne', 'Hawke\'s Bay',
          'Manawatu-Wanganui', 'Marlborough', 'Nelson', 'Northland', 'Otago',
          'Southland', 'Taranaki', 'Tasman', 'Waikato', 'Wellington',
        ],
      };

      const country = parentCompany?.country; // Assuming you have parentCompany data available
      const states = statesByCountry[country] || [];

    return (
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box
          sx={{
            width: '40vw',
            padding: 2,
            height: '100%',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: '8px', right: '8px' }}
            onClick={onClose}
          >
            <ArrowDownward />
          </IconButton>
          <Tabs value={currentTab} onChange={handleTabChange} aria-label="elecfacilities tabs">
            {elecfacilities.map((elecfacilities, index) => (
              <Tab key={elecfacilities.id} label={`Facilities ${elecfacilities.id}`} />
            ))}
            <Tab label="Add Facilities" onClick={() => addFacility()} />
            <Tab label="Submit Data" />
          </Tabs>
          {currentTab < elecfacilities.length ? (
            <Box p={2}>
                <Typography>
                   Electricity Purchased
                    </Typography>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom>
                    Enter Data for elecfacilities {elecfacilities[currentTab].id}
                <DialogTitle>Enter Data for {source}</DialogTitle>
                <Typography>Company: {company}</Typography>
                <Typography>Reporting Period: {reportingPeriod}</Typography>
                <Typography>Month and Year: {month}</Typography>
                {/* <Typography>Year: {year}</Typography> */}
                </Typography>
                <img src={statcombution_logo} alt="Lo go" style={{ height: '170px', marginLeft: '6px'}} />
                    </Box>
                <Typography variant="subtitle1" gutterBottom>
                    {month} {year}
                </Typography>
              <Typography variant="h6" gutterBottom>
                Enter Data for elecfacilities {elecfacilities[currentTab].id}
              </Typography>
             
              <TextField
                label="elecfacilities Description"
                name="elecfacilitiesDescription"
                value={elecfacilities[currentTab].data.ElecelecfacilitiesDescription}
                onChange={(e) => handleChange(e, currentTab)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="elecfacilities Area"
                name="elecfacilitiesArea"
                value={elecfacilities[currentTab].data.ElecelecfacilitiesArea}
                onChange={(e) => handleChange(e, currentTab)}
                fullWidth
                margin="normal"
              />
                <Typography variant="h6" gutterBottom>
                Electricity Details
                </Typography>

                <Table>
                <TableHead>
                    <TableRow>
                    <TableCell>City</TableCell>
                    <TableCell>KWh</TableCell>
                    <TableCell>Emission Factor</TableCell>
                    {/* <TableCell>Action</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {elecfacilities[currentTab].data.Electricity.map((elec, elecIndex) => (
                    <TableRow key={elecIndex}>
                        <TableCell>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id={`state-select-label-${elecIndex}`}>State</InputLabel>
                        <Select
                        labelId={`state-select-label-${elecIndex}`}
                        value={elec.city}
                        onChange={(e) => handleChange(e, currentTab, elecIndex)}
                        name="city"
                        fullWidth
                        >
                        {states.map((state, index) => (
                            <MenuItem key={index} value={state}>
                            {state}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </TableCell>
                        <TableCell>
                        <TextField
                            label="KWh"
                            name="KWh"
                            value={elec.KWh}
                            onChange={(e) => handleChange(e, currentTab, elecIndex)}
                            fullWidth
                            sx={{ width: '100px' }} // Set a specific width
                            margin="normal"
                        />
                        </TableCell>
                        <TableCell sx={{ width: '63%' }}>
                <Grid container alignItems="center" spacing={2}>
                    {/* Fixed width for Switch */}
                    <Grid item sx={{ width: '150px' }}> 
                    {/* <FormControlLabel
                        control={
                        <Switch
                            checked={showEmissionFactor}
                            onChange={handleSwitchChange}
                            color="secondary"
                            size="small"
                        />
                        }
                        label="Emission Factor"
                        labelPlacement="start"
                    /> */}

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '8px' }}>Emission Factor</span>
                        <Switch 
                            checked={showEmissionFactor || false}  // Fallback to false if undefined
                            onChange={(checked) => handleSwitchChange(checked)}  // Pass the checked value to the handler
                            size="small" 
                        />
                        </div>
                    </Grid>

    {/* Fixed width for TextField with spacing when the switch is on */}
    
        {showEmissionFactor && (
            <Grid item sx={{ width: '150px',paddingLeft:'150px' }}> 
                <TextField
                label="Emission Factor"
                name="emFactor"
                value={elec.emFactor}
                onChange={(e) => handleChange(e, currentTab, elecIndex)}
                margin="normal"
                fullWidth
                sx={{ maxWidth: '150px' }} // Ensures the text field doesn't exceed 150px
                />
            </Grid>
            )}
        </Grid>
        </TableCell>
        </TableRow>
        ))}
            </TableBody>
            </Table>
            <Button
            variant="contained"
            color="primary"
            onClick={addRenewableElec}
            >
            Add Renewable Electricity
            </Button> 
{showRenewableElecForm && (
  <Box mt={2}>
    <Typography variant="h6">Renewable Electricity Details</Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>City</TableCell>
          <TableCell>KWh</TableCell>
          <TableCell>Emission Factor</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <TextField
              label="City"
              name="renewableCity"
              value={elecfacilities[currentTab].data.RenewableElec.city || ""}
              onChange={(e) => handleRenewableElecChange(e, 'city')}
              fullWidth
              margin="normal"
            />
          </TableCell>
          <TableCell>
            <TextField
              label="KWh"
              name="renewableKWh"
              value={elecfacilities[currentTab].data.RenewableElec.KWh || ""}
              onChange={(e) => handleRenewableElecChange(e, 'KWh')}
              fullWidth
              margin="normal"
            />
          </TableCell>
          <TableCell>
            {/* <TextField
              label="Emission Factor"
              name="renewableEmFactor"
              value={elecfacilities[currentTab].data.RenewableElec.emFactor || ""}
              onChange={(e) => handleRenewableElecChange(e, 'emFactor')}
              fullWidth
              margin="normal"
            /> */}

<TableCell sx={{ width: '63%' }}>
  <Grid container alignItems="center" spacing={2}>
    <Grid item sx={{ width: '100px', paddingLeft:'150px' }}> {/* Fixed width for Switch */}
    <div style={{ display: 'flex', alignItems: 'center' }}>
    <span style={{ marginRight: '8px' }}>Emission Factor</span>
    <Switch 
        checked={showEmissionFactor || false}  // Fallback to false if undefined
        onChange={(checked) => handleSwitchChange(checked)}  // Pass the checked value to the handler
        size="small" 
    />
    </div>
    </Grid>
    {showEmissionFactor && (
      <Grid item sx={{ width: '150px',paddingLeft:'150px' }}> {/* Fixed width for TextField */}
        <TextField
          label="Emission Factor"
          name="emFactor"
          value={elecfacilities[currentTab].data.RenewableElec.emFactor || ""}
          onChange={(e) => handleRenewableElecChange(e, 'emFactor')}
          margin="normal"
          fullWidth
          sx={{ maxWidth: '150px' }} // Ensures the text field doesn't exceed 150px
        />
      </Grid>
    )}
  </Grid>
</TableCell>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Box>
)}
            </Box>
          ):(
            currentTab >= elecfacilities.length && (
                <Box p={2}>
                  <Typography variant="h6" gutterBottom>
                    Summary
                  </Typography>
                  {elecfacilities.map((elecfacility) => (
                    <Box key={elecfacility.id} mb={2} display="flex" alignItems="center">
                      <Box flex={1}>
                        <Typography variant="subtitle1">
                          Electricity Facility {elecfacility.id}:
                        </Typography>
                        <Typography>Parent Company: {parentCompany.name}</Typography>
                        <Typography>Facility Description: {elecfacility.data.elecfacilitiesDescription}</Typography>
                        <Typography>Facility Area: {elecfacility.data.elecfacilitiesArea}</Typography>
                        <Typography>Electricity Data:</Typography>
              
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>City</TableCell>
                                <TableCell>KWh</TableCell>
                                <TableCell>Emission Factor</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {elecfacility.data.Electricity.map((elec, idx) => (
                                <TableRow key={idx}>
                                  <TableCell>{elec.city}</TableCell>
                                  <TableCell>{elec.KWh}</TableCell>
                                  <TableCell>{elec.emFactor}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
              
                        {/* Renewable Electricity section */}
                        <Typography variant="subtitle1" mt={2}>
                          Renewable Electricity Data:
                        </Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>City</TableCell>
                                <TableCell>KWh</TableCell>
                                <TableCell>Emission Factor</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>{elecfacility.data.RenewableElec.city}</TableCell>
                                <TableCell>{elecfacility.data.RenewableElec.KWh}</TableCell>
                                <TableCell>{elecfacility.data.RenewableElec.emFactor}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                      {elecfacilities.length > 1 && (
                    <IconButton onClick={() => deleteFacility(elecfacilities.id)}>
                      <Delete />
                    </IconButton>)}
                    </Box>
                  ))}
                </Box>
                 
              )  
          )
          
          }
        </Box>
      </Drawer>
    );
  };
  
  export default PurchasedElectricityDialog;
  
