import React, { useState, useEffect } from "react";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";
import Topbar from "./pages/global/Topbar";
import Login from "./auth/Loginpage";
import { Component } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import Loading_animation from "./Loadinganimation";
import Dashboard from "./pages/dashboard/Dashboard.jsx";
import DisplayDashboard from "./DisplayDash/DisplayDash.jsx";
import Spendbased_scope3 from "./components/spendbased_scope3.jsx";
import { SourceProvider } from "./contexts/Sourcecontext.jsx";
import PrivateRoute from "./auth/PrivateRoute.js";
// Private routes to the dashboard and the chart pages
const PrivateDashboard = withAuthenticationRequired(Dashboard);
const PrivateDisplayDashboard = withAuthenticationRequired(DisplayDashboard);
const PrivateSpendbasedScope3 = withAuthenticationRequired(Spendbased_scope3);

 

const App = () => {

  const { isLoading, isAuthenticated } = useAuth0();
  const [theme, colorMode] = useMode();
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  

  return (
    
    <SourceProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login />} 
              onRedirecting={<div><Loading_animation/></div>}
              />
              <Route
                path="/app/*"
                element={
                  <>
                  <MyProSidebarProvider>
                  <div style={{ height: "100%", width: "100%" }}>
                    <main>
                    <Topbar />
                      <Routes>
                      <Route
                          path="/dashboard"
                          element={
                          <PrivateRoute>
                            <PrivateDashboard />
                            </PrivateRoute>}
                          onRedirecting={<div><Loading_animation/></div>} 
                        />
                         <Route
                          path="/displaydashboard"
                          element={
                          <PrivateRoute>
                            <PrivateDisplayDashboard />
                            </PrivateRoute>}
                          onRedirecting={<div><Loading_animation/></div>} 
                        />
                        <Route
                          path="/SpendbasedScope3"
                          element={
                          <PrivateRoute>
                            <PrivateSpendbasedScope3 />
                            </PrivateRoute>}
                          onRedirecting={<div><Loading_animation/></div>} 
                        />
                        </Routes>
                        </main>
                      </div>
                    </MyProSidebarProvider>
                  </>
                }
              />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </SourceProvider>
  );
};
export default App;

