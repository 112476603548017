//Selection of the Sources for each copnay for each reporting period
//At the end it is also showing the table for each reporting period and the sources selected for each company
// components/SourceSelection.js


import React, { useContext } from 'react';
import { Grid, Box } from '@mui/material';
import { SourceContext } from '../contexts/Sourcecontext';
import { FaFire } from 'react-icons/fa';
import StatBox from './StatBox';
import Header from './Header';
import InformationButton from './Informationbutton';
import ReportingPeriodSources from './ReportinPeriodSources';
import CompanySourcesTable from './CompanySourcesTable';
import ExcelUploader from './Exceluploader';

const SourceSelection = ({ company, reportingPeriod }) => {
  const { sourceSelection, handleSourceSelect } = useContext(SourceContext);

  const availableSources = {
    scope1: [
      {
        title: 'Stationary Combustion',
        description: 'Fuels like oil and gas burned in the buildings or equipment owned by your organization',
      },
      {
        title: 'Mobile sources',
        description: 'Purchased fuel by your organization for owned or leased vehicles and mobile equipment',
      },
      {
        title: 'Fugitive Emissions - Refrigeration',
        description: 'Chemical releases from AC and refrigeration equipment',
      },
    ],
    scope2: [
      {
        title: 'Purchased Electricity',
        description: 'Purchased electricity from a utility company or other suppliers.',
      },
      {
        title: 'Purchase steam, heat and cooling',
        description: 'Steam, heat or cooling generated off-site and used in buildings or equipment operated by your organization',
      },
    ],
    scope3: [
      {
        title: 'Employee Commuting',
        description: 'Employee commuting to and from work',
      },
      {
        title: 'Commuting home office equipment',
        description: 'Commuting using home office equipment',
      },
      {
        title: 'Business Travel',
        description: 'Business travel activities',
      },
      {
        title: 'Purchased Goods and Services - Office equipment and supplies',
        description: 'Office equipment and supplies purchased by your organization',
      },
      {
        title: 'Purchased Goods and Services - Professional services',
        description: 'Professional services purchased by your organization',
      },
      {
        title: 'Purchased Goods and Services - Other',
        description: 'Other purchased goods and services',
      },
      {
        title: 'Waste generated in operations',
        description: 'Waste generated in the operations of your organization',
      },
      {
        title: 'Upstream leased Facilities',
        description: 'Leased facilities upstream in the supply chain',
      },
    ],
  };

  const handleChange = (sourceTitle) => {
    handleSourceSelect(reportingPeriod, company, sourceTitle);
  };

  if (!company) {
    return <div>Please select a company to see source options.</div>;
  }

  const periodKey = `${reportingPeriod}-${company.subsidiary_name}`;
  const selectedSourcesCount = sourceSelection[periodKey]?.length || 0;

  return (
    <div>
      <h3>Source Selection for {company.subsidiary_name}</h3>
      <p>Number of Sources Selected: {selectedSourcesCount}</p>
      <Grid container spacing={2}>
        {/* Scope 1 */}
        <Grid item xs={12} sm={12} md={4} borderRight={'2px solid black'}>
          <Box margin="2px 5px" marginBottom="50px">
            <Header
              subtitle="Scope 1"
              information={<InformationButton informationText="Scope 1 emissions!" />}
            />
          </Box>
          {availableSources.scope1.map((source, index) => (
            <Box
              key={index}
              backgroundColor="#C0C0C0"
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="2px 5px"
              borderRadius={3}
              marginBottom="20px"
              border={sourceSelection[periodKey]?.includes(source.title) ? '2px solid blue' : '0.5px solid #000'}
              onClick={() => handleChange(source.title)}
            >
              <StatBox
                title={source.title}
                description={source.description}
                icon={<FaFire style={{ color: 'white', fontSize: '24px' }} />}
              />
            </Box>
          ))}
        </Grid>

        {/* Scope 2 */}
        <Grid item xs={12} sm={12} md={4} borderRight={'2px solid black'}>
          <Box margin="2px 5px" marginBottom="50px">
            <Header
              subtitle="Scope 2"
              information={<InformationButton informationText="Scope 2 emissions!" />}
            />
          </Box>
          {availableSources.scope2.map((source, index) => (
            <Box
              key={index}
              backgroundColor="#C0C0C0"
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="2px 5px"
              borderRadius={3}
              marginBottom="20px"
              border={sourceSelection[periodKey]?.includes(source.title) ? '2px solid blue' : '0.5px solid #000'}
              onClick={() => handleChange(source.title)}
            >
              <StatBox
                title={source.title}
                description={source.description}
                icon={<FaFire style={{ color: 'white', fontSize: '24px' }} />}
              />
            </Box>
          ))}
        </Grid>

        {/* Scope 3 */}
        <Grid item xs={12} sm={12} md={4}>
          <Box margin="2px 5px" marginBottom="50px">
            <Header
              subtitle="Scope 3"
              information={<InformationButton informationText="Scope 3 emissions!" />}
            />
          </Box>
          {availableSources.scope3.map((source, index) => (
            <Box
              key={index}
              backgroundColor="#C0C0C0"
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="2px 5px"
              borderRadius={3}
              marginBottom="20px"
              border={sourceSelection[periodKey]?.includes(source.title) ? '2px solid blue' : '0.5px solid #000'}
              onClick={() => handleChange(source.title)}
            >
              <StatBox
                title={source.title}
                description={source.description}
                icon={<FaFire style={{ color: 'white', fontSize: '24px' }} />}
              />
            </Box>
          ))}
        </Grid>
        <ExcelUploader/>
      </Grid>

      {/* <CompanySourcesTable reportingPeriod={reportingPeriod} companies={company.name} sourceSelection={sourceSelection} /> */}
      {/* <ReportingPeriodSources /> */}
      
    </div>
  );
};

export default SourceSelection;
