import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { ResponsiveBar } from '@nivo/bar';
import { Grid, Box, Typography, Button, Autocomplete, TextField } from '@mui/material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { SourceContext } from '../contexts/Sourcecontext';

const DisplayDashboard = () => {
  const { reportingPeriods, companies, superStatcombusstate, superStatmobilecomb, fetchAndProcessData } = useContext(SourceContext);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  
  useEffect(() => {
    fetchAndProcessData();
  }, []);

  useEffect(() => {
    if (selectedPeriod && selectedCompany) {
      const stationaryData = superStatcombusstate[`stationarycombustion_${selectedCompany}_${selectedPeriod}`] || [];
      const mobileData = superStatmobilecomb[`mobilesources_${selectedCompany}_${selectedPeriod}`] || [];
      setFilteredData([...stationaryData, ...mobileData]);
    }
  }, [selectedPeriod, selectedCompany, superStatcombusstate, superStatmobilecomb]);

  const handlePeriodChange = (event, newValue) => {
    setSelectedPeriod(newValue);
    setSelectedCompany(null); // Reset company selection when period changes
  };

  const handleCompanyChange = (event, newValue) => {
    setSelectedCompany(newValue);
  };


  const calculateTotalEqco2 = (period) => {
    let totalEqco2 = 0;
  
    // Loop through all stationary combustion data
    Object.keys(superStatcombusstate).forEach((key) => {
      if (key.includes(`_${period}`)) {
        superStatcombusstate[key].forEach((facility) => {
          facility.data.emissions.forEach((emission) => {
            totalEqco2 += parseFloat(emission.eqco2) || 0;
          });
        });
      }
    });
  
    // Loop through all mobile combustion data
    Object.keys(superStatmobilecomb).forEach((key) => {
      if (key.includes(`_${period}`)) {
        superStatmobilecomb[key].forEach((fleet) => {
          fleet.data.emissions.forEach((emission) => {
            totalEqco2 += parseFloat(emission.eqco2) || 0;
          });
        });
      }
    });
  
    return totalEqco2;
  };
  

  const EmissionsGraph = ({ totalEqco2 }) => {
    const data = [
      { name: selectedPeriod, eqco2: totalEqco2 }
    ];  
    return (
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="eqco2" fill="#8884d8" />
      </BarChart>
    );
  };
  
  const totalEqco2ForSelectedPeriod = calculateTotalEqco2(selectedPeriod);


  
  const getMonthwiseEqCO2Data = (reportingPeriod) => {
    const monthwiseData = {
      stationaryCombustion: {},
      mobileCombustion: {}
    };
  
    const processState = (state, category) => {
      Object.keys(state).forEach((key) => {
        if (key.includes(reportingPeriod)) {
          const parts = key.split('_');
          const month = parts[3];
  
          state[key].forEach(entry => {
            const eqco2Total = entry.data.emissions.reduce((sum, emission) => sum + emission.eqco2, 0);
  
            if (!monthwiseData[category][month]) {
              monthwiseData[category][month] = 0;
            }
            monthwiseData[category][month] += eqco2Total;
          });
        }
      });
    };
  
    // Process stationary combustion data
    processState(superStatcombusstate, 'stationaryCombustion');
  
    // Process mobile combustion data
    processState(superStatmobilecomb, 'mobileCombustion');
  
    return monthwiseData;
  };
  
// Example usage:
//   const reportingPeriod = "08/2017 - 08/2018";
  const monthwiseEqCO2 = getMonthwiseEqCO2Data(selectedPeriod);
  console.log("monthwiseEqCO2",monthwiseEqCO2);

  const ChartComponent = ({ data }) => {
    const months = Array.from(new Set([
      ...Object.keys(data.stationaryCombustion),
      ...Object.keys(data.mobileCombustion)
    ]));
  
    // Transform data for nivo
    const transformedData = months.map(month => ({
      month,
      stationaryCombustion: data.stationaryCombustion[month] || 0,
      mobileCombustion: data.mobileCombustion[month] || 0
    }));
  
    const chartData = transformedData;
  
    return (
      <div style={{ height: 500 }}>
        <ResponsiveBar
          data={chartData}
          animate={false} // Disable animation
          keys={['stationaryCombustion', 'mobileCombustion']}
          indexBy="month"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          colors={{ scheme: 'nivo' }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#ffea00',
              lineWidth: 6,
              spacing: 10
            }
          ]}
          fill={[
            {
              match: {
                id: 'fries'
              },
              id: 'dots'
            }
          ]}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Months',
            legendPosition: 'middle',
            legendOffset: 36
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Total eqCO2',
            legendPosition: 'middle',
            legendOffset: -40
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          tooltip={({ id, value, color }) => (
            <div
              style={{
                padding: 12,
                color,
                background: '#fff',
                border: `1px solid ${color}`,
                borderRadius: 4
              }}
            >
              <strong>{id}</strong>
              <br />
              {value}
            </div>
          )}
        />
      </div>
    );
  };


  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Emissions Dashboard
      </Typography>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={reportingPeriods}
            value={selectedPeriod}
            onChange={handlePeriodChange}
            renderInput={(params) => <TextField {...params} label="Select Reporting Period" variant="outlined" />}
          />
        </Grid>
        <div>
      <h2>CO2 Emissions for Reporting Period {selectedPeriod}</h2>
      <EmissionsGraph totalEqco2={totalEqco2ForSelectedPeriod} />
        </div>
        <div style={{ width: '600px', height: '400px' }}>
      <h2>Month wise CO2 Emissions for Reporting Period {selectedPeriod}</h2>

        <ChartComponent data={monthwiseEqCO2} />
        </div>
        {/* <Grid item xs={12} md={6}>
          <Autocomplete
            options={selectedPeriod ? companies[selectedPeriod].map(company => company.subsidiary_name) : []}
            value={selectedCompany}
            onChange={handleCompanyChange}
            renderInput={(params) => <TextField {...params} label="Select Company" variant="outlined" />}
          />
        </Grid> */}
      </Grid>

      {/* {filteredData.length > 0 && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6">Stationary Combustion Data</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={filteredData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="data.facilityDescription" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="data.fuels[0].quantityCombusted" stroke="#8884d8" />
              <Line type="monotone" dataKey="data.emissions[0].co2" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>

          <Typography variant="h6" sx={{ mt: 4 }}>
            Mobile Combustion Data
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={filteredData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="data.fleetDescription" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="data.vehicles[0].fuelQuantity" stroke="#ff7300" />
              <Line type="monotone" dataKey="data.emissions[0].co2" stroke="#387908" />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      )} */}
    </Box>
  );
};

export default DisplayDashboard;