import React, { useContext } from 'react';
import { SourceContext } from '../contexts/Sourcecontext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const CompanyList = ({ reportingPeriod }) => {
  const { companies, deleteCompany, handleCompanySelect, parentCompany } = useContext(SourceContext);

  const periodCompanies = companies[reportingPeriod] || [];

  const handleDelete = (companyName) => {
    deleteCompany(reportingPeriod, companyName);
    try {
      const response =  axios.post('http://localhost:5000/api/deletesubsidiaries', {
        parentCompany:parentCompany,
        reportingPeriod: reportingPeriod,
        company: companyName.trim(),
      });
      console.log(response.data); // You can handle the response data here if needed
    } catch (error) {
      console.error('Error adding company:', error.response || error.message);
    }
  };

  return (
    <TableContainer >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company Name</TableCell>
            <TableCell>Equity (%)</TableCell>
            <TableCell>Operational Percentage (%)</TableCell>
            <TableCell>Financial Percentage (%)</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {periodCompanies.map((company, index) => (
            <TableRow key={index} onClick={() => handleCompanySelect(company)}>
              <TableCell>{company.subsidiary_name}</TableCell>
              <TableCell>{company.equity_percentage}</TableCell>
              <TableCell>{company. operational_percentage}</TableCell>
              <TableCell>{company.financial_percentage}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleDelete(company.name)} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompanyList;
