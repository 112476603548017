import React, { useState, useContext } from 'react';
import { SourceContext } from '../contexts/Sourcecontext';
import { TextField, Button, Box, Grid } from '@mui/material';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const CompanyForm = ({ reportingPeriod }) => {
  const [companyName, setCompanyName] = useState('');
  const [equity, setEquity] = useState('');
  const [operationalPercentage, setOperationalPercentage] = useState('');
  const [financialPercentage, setFinancialPercentage] = useState('');
  const { addCompany, parentCompany, reportingPeriods, companies } = useContext(SourceContext);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (companyName.trim() && equity.trim() && operationalPercentage.trim() && financialPercentage.trim()) {
      addCompany(reportingPeriod, {
        subsidiary_name: companyName.trim(),
        equity_percentage: equity.trim(),
        operational_percentage: operationalPercentage.trim(),
        financial_percentage: financialPercentage.trim(),
      });
      console.log(companies)
       // Check if the state has been updated
      // setTimeout(async () => {
      // console.log('Companies after adding (after timeout):', companies);

  //     try {
  //       const token = await  getAccessTokenSilently();
        
  //       const response = await axios.post('http://localhost:5000/api/addsubsidiary',{ 
  //         // username: user.name,
  //         parentCompany:parentCompany.name,
  //         parentCompanycountry:parentCompany.country,
  //         parentCompanystate:parentCompany.state,
  //         reportingPeriods:reportingPeriods,
  //         companies:companies,
  //        } , {
  //         headers: {
  //             'Authorization': `Bearer ${token}`,
  //           },
  //         });
  //   }
    
  //   catch (error) {
  //   // Handle any errors here
  //   console.error(error.response);
  // } })
      setCompanyName('');
      setEquity('');
      setOperationalPercentage('');
      setFinancialPercentage('');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={2}>
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Equity Percentage"
            variant="outlined"
            fullWidth
            value={equity}
            onChange={(e) => setEquity(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Operational Percentage"
            variant="outlined"
            fullWidth
            value={operationalPercentage}
            onChange={(e) => setOperationalPercentage(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            label="Financial Percentage"
            variant="outlined"
            fullWidth
            value={financialPercentage}
            onChange={(e) => setFinancialPercentage(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box textAlign="center">
            <Button type="submit" variant="contained" color="primary">
              Add Company
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default CompanyForm;
