import React, { useState, useEffect, useRef, useContext } from 'react';
import { Select, Box, Button, Drawer, Table, TableBody, TableCell, TableHead, TableRow, TextField, MenuItem, Grid  } from '@material-ui/core';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { Accordion, AccordionSummary, AccordionDetails, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import stringSimilarity from 'string-similarity'; // Import string similarity package
import { useAuth0 } from '@auth0/auth0-react';
import { SourceContext } from '../contexts/Sourcecontext';
import { Avatar, Paper } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload'; // Icon for the upload button
import statcombution_logo from './stationary_combustion.png'
import { Switch } from 'antd';

function StationaryCombustionExcelUpload({ reportingPeriod, company}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [isReview, setIsReview] = useState(false);
  const [isSorttoSubmit, setisSorttoSubmit] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const { parentCompany } = useContext(SourceContext);
  const { getAccessTokenSilently } = useAuth0();

  // Sample picture for professional look
  const backgroundImageUrl = statcombution_logo
  // Handle Excel File Upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const binaryString = evt.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);

      // Make a copy of the 'FuelCombusted' column into 'OriginalFuelCombusted'
    const dataWithOriginalFuel = data.map(row => ({
      ...row,  // Spread the rest of the row data
      OriginalFuelCombusted: row.FuelCombusted || null, // Copy the FuelCombusted value to a new column
      FuelCombusted: null // Set FuelCombusted to null

    }));
    const updatedData = dataWithOriginalFuel.map((row) => {
      const closestFuels = getClosestMatches(row.OriginalFuelCombusted);
      const matchedFuel = closestFuels.includes(row.OriginalFuelCombusted) ? row.OriginalFuelCombusted : ''; // Replace with a default value if no match
      return { ...row, FuelCombusted: matchedFuel }; // Add/update the Fuel Combusted column
    });

    // Set the modified data with the original fuel column added
    setParsedData(updatedData);  
      // setParsedData(data);  // Parse and set the data
    //   setDrawerOpen(false);  // Close the drawer after upload
      setIsReview(true);     // Move to review page
      setisSorttoSubmit(false)
    };
    reader.readAsBinaryString(file);
  };

  // Render editable table for review
  const handleDataChange = (index, field, value) => {
    const updatedData = [...parsedData];
    updatedData[index][field] = value;
    setParsedData(updatedData);
  };

  // Submit the data to backend
  const handleSubmit = async() => {
    const token = await getAccessTokenSilently();

    // Construct the payload
    const payload = {
      parentcompany: parentCompany.name,
      parentcompany_country: parentCompany.country,
      company: company.subsidiary_name,
      period: reportingPeriod,
      Allrows: filteredData,
    };
  
    // Log the payload to the console
    console.log('Payload to be sent to backend:', payload);
  
    try {
      const response = await axios.post('http://localhost:5000/api/stationarycombustionfromExcel', {
        payload
      },{
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error adding company:', error.response || error.message);
    }

  };

//the subsidiary for which data is being uploaded from the excel
const selectedSubsidiary = company.subsidiary_name

//the first filter filters out any data whcih doesnt belong to this subsidiary
const filteredData = parsedData.filter(row => row.SubsidiaryName === selectedSubsidiary);

const [selectedFuel, setSelectedFuel] = useState({}); // Store selected fuel for each row

// // Merge selectedFuel into filtered data
// const mergedData = filteredData.map((row, index) => ({
//   ...row,
//   FuelCombusted: selectedFuel[index] || row.FuelCombusted, // Merge selected fuel if present
// }));

const groupedData = filteredData.reduce((acc, row) => {
    const monthYear = `${row.Month}`;
    const facilityId = row.FacilityID;

    // Initialize month/year if it doesn't exist
    if (!acc[monthYear]) {
        acc[monthYear] = {};
    }

    // Initialize facility if it doesn't exist under the month/year
    if (!acc[monthYear][facilityId]) {
        acc[monthYear][facilityId] = [];
    }

    // Push the row (fuel data) under the facility
    acc[monthYear][facilityId].push(row);

    return acc;
}, {});

// Split the reporting period to extract start and end dates in MM/YYYY format
const [startPeriod, endPeriod] = reportingPeriod.split(' - ');

// Function to parse month/year in different formats
const parseMonthYear = (dateString) => {
    const monthNames = {
        '01': 'January', '02': 'February', '03': 'March',
        '04': 'April', '05': 'May', '06': 'June',
        '07': 'July', '08': 'August', '09': 'September',
        '10': 'October', '11': 'November', '12': 'December',
        'Jan': 'January', 'Feb': 'February', 'Mar': 'March',
        'Apr': 'April', 'May': 'May', 'Jun': 'June',
        'Jul': 'July', 'Aug': 'August', 'Sep': 'September',
        'Oct': 'October', 'Nov': 'November', 'Dec': 'December'
    };

    // Try matching MM/YYYY format
    const mmyyyyRegex = /^(\d{2})\/(\d{4})$/;
    const mmmmyyyyRegex = /^(January|February|March|April|May|June|July|August|September|October|November|December)\/(\d{4})$/;
    const mmmyyyyRegex = /^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\/(\d{4})$/;

    let match;

    // Check MM/YYYY format
    if (match = dateString.match(mmyyyyRegex)) {
        const month = match[1];
        const year = match[2];
        return new Date(`${year}-${month}-01`);
    }
    // Check MMMM/YYYY format
    else if (match = dateString.match(mmmmyyyyRegex)) {
        const month = Object.keys(monthNames).find(key => monthNames[key] === match[1]);
        const year = match[2];
        return new Date(`${year}-${month}-01`);
    }
    // Check MMM/YYYY format
    else if (match = dateString.match(mmmyyyyRegex)) {
        const month = Object.keys(monthNames).find(key => monthNames[key] === match[1]);
        const year = match[2];
        return new Date(`${year}-${month}-01`);
    }

    // Return null if no format matched
    return null;
};

// Function to check if a row falls within the reporting period
const checkWithinReportingPeriod = (monthYear) => {
    const entryDate = parseMonthYear(monthYear);
    if (!entryDate) return false; // Invalid date format

    const startDate = parseMonthYear(startPeriod);
    const endDate = parseMonthYear(endPeriod);
    
    // Check if entryDate is within the reporting period (inclusive)
    return entryDate >= startDate && entryDate <= endDate;
};

// // Function to check if a row's Subsidiary_Name matches the selected subsidiary
const checkSubsidiaryName = (subsidiaryName) => {
    return subsidiaryName === selectedSubsidiary;
};


const [viewByFacility, setViewByFacility] = useState(false);

  // Toggle function for switching between views
  const handleViewChange = () => {
    setViewByFacility(!viewByFacility);
  };


const final_filteredData = Object.keys(groupedData).reduce((acc, monthYear) => {
    // console.log("Checking monthYear:", monthYear); // Log the current monthYear being checked
    if (checkWithinReportingPeriod(monthYear)) {
        acc[monthYear] = groupedData[monthYear];
        // console.log("Added to filteredData:", monthYear); // Log when monthYear is added
    } else {
        // console.log("Not in period:", monthYear); // Log when it's not added
    }
    return acc;
}, {});


// Facility-wise data transformation (group by facilityId)
const facilityWiseData = Object.entries(final_filteredData).reduce((acc, [monthYear, facilities]) => {
  Object.entries(facilities).forEach(([facilityId, fuels]) => {
    if (!acc[facilityId]) {
      acc[facilityId] = [];
    }
    acc[facilityId].push({ monthYear, fuels });
  });
  return acc;
}, {});

console.log("Final Filtered Data:", filteredData); // Log the final filteredData

const fuelList= [
  'Bituminous Coal', 'Sub-bituminous Coal', 'Anthracite', 'Brown Coal (Lignite)', 'Coking Coal',
  'Coal Briquettes', 'Coal Coke', 'Coal Tar', 'Other Solid Fossil Fuels', 'Recycled Industrial Materials (fossil-derived)',
  'Recycled Passenger Car Tyres', 'Recycled Truck and Off-road Tyres', 'Non-biomass Municipal Materials', 'Dry Wood',
  'Green and Air Dried Wood', 'Sulphite Lyes', 'Bagasse', 'Biomass (Municipal and Industrial)', 'Charcoal', 'Primary Solid Biomass Fuels',
  'Natural Gas', 'Coal Seam Methane', 'Coal Mine Waste Gas', 'Compressed Natural Gas', 'Unprocessed Natural Gas', 'Ethane',
  'Coke Oven Gas', 'Blast Furnace Gas', 'Town Gas', 'Liquefied Natural Gas',
  'Other Gaseous Fossil Fuels', 'Landfill Biogas (Methane only)', 'Sludge Biogas (Methane only)', 'Other Captured Biogas', 'Biomethane',
  'Petroleum based oils (other than petroleum based oil used as fuel, e.g., lubricants)', 
  'Petroleum based greases', 
  'Crude oil including crude oil condensates', 
  'Other natural gas liquids', 
  'Automotive gasoline/petrol (other than for use as fuel in an aircraft)', 
  'Aviation gasoline', 
  'Kerosene (other than for use as fuel in an aircraft)', 
  'Aviation turbine fuel/kerosene', 
  'Heating oil', 
  'Diesel oil', 
  'Fuel oil', 
  'Liquefied aromatic hydrocarbons', 
  'Solvents: mineral turpentine or white spirits', 
  'Liquefied petroleum gas (LPG)', 
  'Naphtha', 
  'Petroleum coke', 
  'Refinery gas and liquids', 
  'Refinery coke', 
  'Petroleum based products other than mentioned in the items above', 
  'Biodiesel', 
  'Ethanol for use as a fuel in an internal combustion engine', 
  'Biofuels other than those mentioned in the items above and below', 
  'Renewable aviation kerosene', 
  'Renewable diesel'
];


// Initialize selectedFuel based on parsedData
useEffect(() => {
  // Ensure selectedFuel array has the same length as parsedData, defaulting to null or original fuel
  const initialSelectedFuel = parsedData.map(row => row.FuelCombusted || null);
  setSelectedFuel(initialSelectedFuel);
}, [parsedData]);


  const getClosestMatches = (fuel) => {
    const matches = stringSimilarity.findBestMatch(fuel, fuelList);
    return matches.ratings
      .filter((match) => match.rating > 0.2) // You can set a threshold here
      .sort((a, b) => b.rating - a.rating)
      .map((match) => match.target);
  };

  const handleFuelChange = (index, value) => {
    setSelectedFuel((prev) => ({
      ...prev,
      [index]: value,
    }));
    handleDataChange(index, 'FuelCombusted', value);

    // Create a copy of the current selectedFuel array and update the relevant index
  const updatedSelectedFuel = [...selectedFuel];
  updatedSelectedFuel[index] = value;
    // Validate if all non-strikethrough rows have a selected fuel
  validateFuelSelection(updatedSelectedFuel);

  };

  /// Validate whether all non-strikethrough rows have a fuel selected
const validateFuelSelection = (updatedSelectedFuel) => {
  const allValid = parsedData.every((row, index) => {
    const isWithinReportingPeriod = checkWithinReportingPeriod(row.Month);
    const iscompanysubsidiary = checkSubsidiaryName(row.SubsidiaryName);

    // Only validate rows that are not strikethrough
    if (isWithinReportingPeriod && iscompanysubsidiary) {
      return updatedSelectedFuel[index] !== null && updatedSelectedFuel[index] !== ""; // Ensure a valid fuel is selected for these rows
    }
    return true; // Skip validation for strikethrough rows
  });

  // Disable "Next" button if any relevant row does not have a selected fuel
  setIsNextDisabled(!allValid);
};


const transformData=(excelData)=> {
  const facilitiesMap = {};

  excelData.forEach(entry => {
    const facilityId = entry.FacilityID;

    if (!facilitiesMap[facilityId]) {
      facilitiesMap[facilityId] = {
        id: facilityId,
        data: {
          facilityDescription: entry.FacilityDescription,
          facilityArea: entry.FacilityArea,
          fuels: []
        }
      };
    }

    facilitiesMap[facilityId].data.fuels.push({
      fuelCombusted: entry.FuelCombusted,
      quantityCombusted: entry.Quantity,
      units: entry.Units,
      heatingvalue: entry.HeatingValue
    });
  });

  return Object.values(facilitiesMap);
}

const transformedFacilities = transformData(filteredData);



  return (
    <div >
      <Button onClick={() => setDrawerOpen(true)}>Upload Excel</Button>
  
  <Drawer anchor="right" 
  // sx={{
  //   width: '700px',
  //   padding: 3,
  //   height: '100%',
  //   backgroundColor: '#f5f5f5',
  // overflowY: 'auto', // Enable vertical scrolling
  //                 padding: '10px',}}
  open={drawerOpen} onClose={() => setDrawerOpen(false)}>
    
  <Box
    sx={{
      width: '700px',
      padding: 3,
      height: '100%',
      backgroundColor: '#f5f5f5',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxHeight: '900px', // Set a max height for the accordion container
      overflowY: 'auto', // Enable vertical scrolling
      overflowX:'auto'
    }}
  >
    {!isReview && !isSorttoSubmit && (
      <Paper
        elevation={10}
        sx={{
          padding: 4,
          maxWidth: '70vh',
          backgroundColor: '#ffffff',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          {/* Left side: Text details */}
          <Grid item xs={6}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
              Upload Excel File For Stationary Combustion
            </Typography>

            <Typography variant="h4" sx={{ fontWeight: 'normal', color: '#333',marginBottom: 2, marginTop:5 }}>
              {`For Subsidiary: ${company.subsidiary_name}`}
            </Typography>

            <Typography variant="h5" sx={{ fontWeight: 'normal', color: '#333',marginBottom: 2, marginTop:2 }}>
              {`Reporting Period: ${reportingPeriod}`}
            </Typography>

            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileUpload}
              style={{
                display: 'block',
                marginTop: '20px',
                fontSize: '16px',
                padding: '10px',
                width: '100%',
              }}
            />
          </Grid>

          {/* Right side: Professional Image */}
          <Grid item xs={6}>
            <Avatar
              variant="square"
              alt="Excel Upload"
              src={statcombution_logo}
              sx={{
                width: '100%',
                height: 'auto',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    )}
      {/* Review Data Page */}
      {isReview && (
        <Grid container spacing={2} style={{ maxWidth: '100%', maxHeight: '100%', overflowX: 'auto', marginTop:'10px' }}>
        <Grid item xs={12}>
          {/* Your content */}
      
        {/* <div>
        <div style={{ padding: '20px' }}> Add padding to the container */}
       <Paper elevation={10} style={{ padding: '20px', marginBottom: '20px', marginTop:'20px'}}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Review data for {company.subsidiary_name} (Stationary Combustion)
            </Typography>
            <Typography variant="h5" color="textSecondary">
              Reporting Period: {reportingPeriod}
            </Typography>
          </Grid>
          {/* <h3>Review Data for {company.subsidiary_name} for Stationary Combustion for the reporting period : {reportingPeriod}</h3> */}
          <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Month/Year</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Subsidiary Name</TableCell>
                <TableCell>Facility ID</TableCell>
                <TableCell>Facility Description</TableCell>
                <TableCell>Facility Area</TableCell>
                <TableCell>Fuel Combusted</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Units</TableCell>
                <TableCell>Heating Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {parsedData.map((row, index) => {
            // Check if the data row is within the reporting period
            const isWithinReportingPeriod = checkWithinReportingPeriod(row.Month);
            const iscompanysubsidiary = checkSubsidiaryName(row.SubsidiaryName);
            // Get closest matches for the fuel from the Excel data
            const closestFuels = getClosestMatches(row.OriginalFuelCombusted);

            return (
              <TableRow 
                key={index} 
                style={isWithinReportingPeriod && iscompanysubsidiary ? {} : { textDecoration: 'line-through', color: 'blue' }} // Apply strikethrough and color if outside reporting period
              >
                <TableCell>{row.Month}</TableCell>
                <TableCell>{row.Year}</TableCell>
                <TableCell>{row.SubsidiaryName}</TableCell>
                <TableCell>{row.FacilityID}</TableCell>
                <TableCell>{row.FacilityDescription}</TableCell>
                <TableCell>{row.FacilityArea}</TableCell>
                <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      Original Value:{row.OriginalFuelCombusted}
                    </Typography>
                      {/* Dropdown for Fuel Combusted */}
                      <Select
                        value={selectedFuel[index] ||
                          (closestFuels.includes(row.OriginalFuelCombusted) && row.OriginalFuelCombusted)
                        }
                        onChange={(e) => handleFuelChange(index, e.target.value)}
                        
                        disabled={!isWithinReportingPeriod || !iscompanysubsidiary} // Disable Select for strikethrough rows

                      >
                          {/* Add a placeholder item for selection
                      <MenuItem value="" disabled>
                        Select Fuel
                      </MenuItem> */}
                        {closestFuels.map((fuel, i) => (
                          <MenuItem key={i} value={fuel}>
                            {fuel}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                <TableCell>
                  <TextField value={row.Quantity} onChange={(e) => handleDataChange(index, 'Quantity', e.target.value)} />
                </TableCell>
                <TableCell>
                  <TextField value={row.Units} onChange={(e) => handleDataChange(index, 'Units', e.target.value)} />
                </TableCell>
                <TableCell>
                  <TextField value={row.HeatingValue} onChange={(e) => handleDataChange(index, 'HeatingValue', e.target.value)} />
                </TableCell>
              </TableRow>
            );
          })}
            </TableBody>
          </Table>
          </div>
          
            
        </Grid>
        </Paper>
      {/* </Grid> */}

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
    <Button 
      variant="outlined" 
      onClick={() => setIsReview(false)} >Back</Button>
    <Button 
      variant="contained" 
      color="primary" 
      onClick={() => { setisSorttoSubmit(true); setIsReview(false);
            
      }}disabled={isNextDisabled} // Disable the "Next" button until validation passes
      >
       Sort and review data
       </Button>
  </div>
      
        {/* </div>
        </div> */}
        </Grid>
        </Grid>
      )}

{isSorttoSubmit && (
  <div>
     {/* <div style={{ padding: '20px' }}> Add padding to the container */}
     <Grid container spacing={2} style={{ maxWidth: '100%', maxHeight: '100%', overflowX: 'auto', marginTop:'10px' }}>
     <Grid item xs={12}>
    <Paper elevation={10} style={{ padding: '20px', marginBottom: '20px' }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Review and Submit Data for {company.subsidiary_name} (Stationary Combustion)
            </Typography>
            <Typography variant="h5" color="textSecondary">
              Reporting Period: {reportingPeriod}
            </Typography>
          </Grid>

          {/* Switch View */}
          <Box sx={{ flexGrow: 1, marginBottom: '20px' }}>
      <Grid container justifyContent="center" alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom align="center">
            Toggle between Month-wise or Facility-wise views
          </Typography>
          <Grid container justifyContent="center" alignItems="center">
            <Switch
              checked={viewByFacility}
              onChange={handleViewChange}
              inputProps={{ 'aria-label': 'Switch between Month-wise and Facility-wise View' }}
            />
            <Typography variant="body1" style={{ marginLeft: '10px' }}>
              {viewByFacility ? 'Facility-wise View' : 'Month-wise View'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>

          {/* Data display */}
          <Grid item xs={12}>
          <Box
                sx={{
                  maxHeight: '600px', // Set a max height for the accordion container
                  overflowY: 'auto', // Enable vertical scrolling
                  padding: '20px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
              >
            {!viewByFacility ? (
              <div>
                {Object.entries(final_filteredData).map(([monthYear, facilities]) => (
                  <Accordion key={monthYear} style={{ marginBottom: '10px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6">{monthYear}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table size="small">
                        <TableBody>
                          {Object.entries(facilities).map(([facilityId, fuels]) => (
                            <React.Fragment key={facilityId}>
                              <TableRow>
                                <TableCell colSpan={9} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                                  Facility ID: {facilityId}, Name: {fuels[0].FacilityDescription}, Area: {fuels[0].FacilityArea} m²
                                </TableCell>
                              </TableRow>
                              {fuels.map((fuel, fuelIndex) => (
                                <TableRow key={fuelIndex}>
                                  <TableCell></TableCell>
                                  <TableCell colSpan={3}>{fuel.FuelCombusted}</TableCell>
                                  <TableCell>
                                    <TextField
                                      label="Quantity"
                                      size="small"
                                      variant="outlined"
                                      value={fuel.Quantity}
                                      onChange={(e) => handleDataChange(fuelIndex, 'Quantity', e.target.value)}
                                      fullWidth
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      label="Units"
                                      size="small"
                                      variant="outlined"
                                      value={fuel.Units}
                                      onChange={(e) => handleDataChange(fuelIndex, 'Units', e.target.value)}
                                      fullWidth
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      label="Heating Value"
                                      size="small"
                                      variant="outlined"
                                      value={fuel.HeatingValue}
                                      onChange={(e) => handleDataChange(fuelIndex, 'HeatingValue', e.target.value)}
                                      fullWidth
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            ) : (
              Object.entries(facilityWiseData).map(([facilityId, facilityData]) => (
                <Accordion key={facilityId} style={{ marginBottom: '10px' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Facility ID: {facilityId}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table size="small">
                      <TableBody>
                        {facilityData.map(({ monthYear, fuels }, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell colSpan={9} style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
                                {monthYear}
                              </TableCell>
                            </TableRow>
                            {fuels.map((fuel, fuelIndex) => (
                              <TableRow key={fuelIndex}>
                                <TableCell></TableCell>
                                <TableCell colSpan={3}>{fuel.FuelCombusted}</TableCell>
                                <TableCell>
                                  <TextField
                                    label="Quantity"
                                    size="small"
                                    variant="outlined"
                                    value={fuel.Quantity}
                                    onChange={(e) => handleDataChange(fuelIndex, 'Quantity', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Units"
                                    size="small"
                                    variant="outlined"
                                    value={fuel.Units}
                                    onChange={(e) => handleDataChange(fuelIndex, 'Units', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Heating Value"
                                    size="small"
                                    variant="outlined"
                                    value={fuel.HeatingValue}
                                    onChange={(e) => handleDataChange(fuelIndex, 'HeatingValue', e.target.value)}
                                    fullWidth
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </React.Fragment>
                        ))}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              ))
            )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
      </Grid>
      </Grid>
      {/* </div> */}
  

  {/* Actions Section */}
  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
    <Button 
      variant="outlined" 
      onClick={() => { setisSorttoSubmit(false); setIsReview(true); }}
    >
      Back
    </Button>

    <Button 
      variant="contained" 
      color="primary" 
      onClick={handleSubmit}
    >
      Submit Data
    </Button>
  </div>
</div>
)}
</Box>
      </Drawer>
    </div>
  );
}
export default StationaryCombustionExcelUpload;
