import { Box, Typography, useTheme } from "@mui/material";

import { tokens } from "../theme";

const StatBox = ({ title, subtitle, bar, increase, icon, Wdir, description }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 10px" p="12px 0" display="flex" flexDirection="column">
  <Box display="flex" alignItems="center">
    {/* Logo */}
    <div style={{ marginRight: '10px', marginTop:'-40px' }}>{icon}</div>
    {/* Title and description */}
    <div>
      <Typography
        fontSize={"20px"}
        fontWeight="medium"
        sx={{ color: colors.grey[100]}}
        justifyContent={'top'}
        paddingLeft={'10px'}

      >
        {title}
      </Typography>
      <Typography
        fontSize={"14px"}
        fontWeight="medium"
        sx={{ color: colors.grey[400]}}
        justifyContent={'top'}
        marginTop={"10px"}
        paddingLeft={'10px'}

      >
        {description}
      </Typography>
    </div>
  </Box>
  <Box mt={1} display="flex" justifyContent="space-between">
    {/* Content for the lower part of the box */}
  </Box>
</Box>

  );
};

export default StatBox;
