// components/CompaniesTabs.js
import React, { useContext, useState } from 'react';
import { Tabs, Tab, Box, Paper, Button } from '@mui/material';
import { SourceContext } from '../contexts/Sourcecontext';
import SourceSelection from './CompaniesTabs';
import MonthlyDataEntry from './MonthlyDataEntry';
import axios from 'axios';

const CompaniesTabsReportingPeriod = ({ reportingPeriod }) => {
  const { companies, handleCompanySelect } = useContext(SourceContext);
  const { sourceSelection, parentCompany } = useContext(SourceContext);
  const periodCompanies = companies[reportingPeriod] || [];
  const [selectedTab, setSelectedTab] = useState(0);
  const [monthlydataentry, setmonthlydataentry] =useState(false)

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    handleCompanySelect(periodCompanies[newValue]);
  };

  const handlemonthlydataentry = () =>{
    setmonthlydataentry(true);
    try {
      const response =  axios.post('http://localhost:5000/api/sourceselection', {
        parentCompany:parentCompany,
        sourceselection: sourceSelection,
      });
      console.log(response.data); // You can handle the response data here if needed
    } catch (error) {
      console.error('Error adding company:', error.response || error.message); 
    }
  }

  const handlebacktosourceselection = () =>{
    setmonthlydataentry(false);
  }
  // console.log(periodCompanies)
  // console.log(sourceSelection)
  return (
    <Paper>
      <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons="auto">
        {periodCompanies.map((company, index) => (
          <Tab key={index} label={company.subsidiary_name} />
        ))}
      </Tabs>
      {periodCompanies.length > 0 && !monthlydataentry && (
        <Box p={3}>
          <SourceSelection company={periodCompanies[selectedTab]} reportingPeriod={reportingPeriod} />
          <Button variant="contained" onClick={handlemonthlydataentry}
           style={{
            width: '100%',
            height: '50px',
            fontSize: '14px'
          }}>
         Proceed to monthly data Entry
       </Button>
        </Box>
      )}
      {periodCompanies.length > 0 && monthlydataentry && (
        <Box p={3}>
          {/* <SourceSelection company={periodCompanies[selectedTab]} reportingPeriod={reportingPeriod} /> */}
          <MonthlyDataEntry reportingPeriod={reportingPeriod} company={periodCompanies[selectedTab]} />
                 <Button variant="contained" onClick={handlebacktosourceselection}
                  style={{
                    width: '100%',
                    height: '50px',
                    fontSize: '14px'
                  }}>
         Back to source selection
       </Button>
        </Box>
      )}
    </Paper>
  );
};

export default CompaniesTabsReportingPeriod;