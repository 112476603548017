import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import io, { Socket } from 'socket.io-client';
import { useState, useEffect } from "react";
// import useSocket from "../socket";

const Profile = () => {

  const { user, isAuthenticated, isLoading } = useAuth0();
  // const { data } = useSocket();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    isAuthenticated && (
      <div>
      <h3>Welcome to Udak's Emission Tracker {user.name}</h3>
      </div>
    )
  );
};

export default Profile;