import React, { useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { SourceContext } from '../contexts/Sourcecontext';
import StationaryCombustionDialog from './StationaryCombustionDialog';
import dayjs from 'dayjs';
import MobileResourceDialog from './MobileResourceDialog'
import RefreigerationDialog from './RefrigerationDialog';
import FugitiveDialog from './FugitiveDialog';
import PurchasedElectricityDialog from './PurchasedElectricityDialog';
import PurchasedSteamHeatingCoolingDialog from './PurchasedSteamHeatingCoolingDialog';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import StationaryCombustionExcelUpload from './StationaryCombustionExcelUploader';
// Import other specialized dialogs as needed

const MonthlyDataEntry = ({ reportingPeriod, company }) => {
  const { sourceSelection } = useContext(SourceContext);
  const { superStatcombusstate } = useContext(SourceContext); // Access the superState
  const { superStatmobilecomb } = useContext(SourceContext); // Access the superState
  const { fetchAndProcessData}= useContext(SourceContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentMonth, setCurrentMonth] = useState('');
  const [currentSource, setCurrentSource] = useState('');
  const [formData, setFormData] = useState({});
  
  useEffect(() => {
    fetchAndProcessData();
  }, []);

  // Dynamically generate months array based on reporting period
  const generateMonthsArray = () => {
    const startDate = dayjs(reportingPeriod.split(' - ')[0], 'MM/YYYY');
    const endDate = dayjs(reportingPeriod.split(' - ')[1], 'MM/YYYY');
    const months = [];
    let currentMonth = startDate;
    while (currentMonth.isBefore(endDate) || currentMonth.isSame(endDate, 'month')) {
      months.push(currentMonth.format('MMMM YYYY')); // Format month as "Month Year"
      currentMonth = currentMonth.add(1, 'month');
    }
    return months;
  };

  const months = generateMonthsArray();

  const handleOpenDialog = (month, source) => {
    setCurrentMonth(month);
    setCurrentSource(source);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentMonth('');
    setCurrentSource('');
    setFormData({});
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitData = () => {
    console.log(`Data for ${company.subsidiary_name} - ${currentSource} in ${currentMonth}:`, formData);
    handleCloseDialog();
  };

  const periodKey = `${reportingPeriod}-${company.subsidiary_name}`;
  const selectedSources = sourceSelection[periodKey] || [];
  
  return (
    <Box p={3}>
      <div>
        <h3>Monthly Data Entry for {company.subsidiary_name}</h3>
      <TableContainer component={Paper} style={{ display: 'block', maxWidth: '90%', overflowX: 'auto' }} >
          <Table style={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell style={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'white',
                  zIndex: 1,
                  minWidth: 150, // Ensures the Source column has a fixed width
                  }}>Source
                  </TableCell>
                {months.map((month) => (
                  <TableCell key={month}>{month}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedSources.map((source, sourceIndex) => (
                <TableRow key={sourceIndex}>
                <TableCell style={{
                  position: 'sticky',
                  left: 0,
                  backgroundColor: 'white',
                  zIndex: 1,
                  minWidth: 150, // Ensures the Source column has a fixed width
                  }}>{source} 
                  {source === 'Stationary Combustion' && <StationaryCombustionExcelUpload reportingPeriod={reportingPeriod} company ={company} />}
                  {/* Add more source excel uploaders here */}
               </TableCell>
                {months.map((month) => {
                  // Only handle "Stationary Combustion" source
                  if (source === 'Stationary Combustion') {
                    const key = `stationarycombustion_${company.subsidiary_name}_${month}_${reportingPeriod}`;
                    const dataEntered = superStatcombusstate[key] && superStatcombusstate[key].length > 0;
                    const data = superStatcombusstate[key] || [];
                    // console.log("dataEntered",superStatcombusstate[key])
                    return (
                      <TableCell key={month}>
                        
                        {dataEntered ? (
                          <div>
                          {(() => {
                            const totalEqco2 = data.reduce((total, item) => {
                              const itemTotal = item.data.emissions.reduce((itemSum, emission) => {
                                return itemSum + (emission.eqco2 || 0);
                              }, 0);
                              return total + itemTotal;
                            }, 0);
                            return <span>{totalEqco2.toFixed(2)}</span>; // Display the total eqco2
                          })()}
                          <Button onClick={() => handleOpenDialog(month, source)}>EDIT</Button>
                        </div>
                        ) : (
                          <Button onClick={() => handleOpenDialog(month, source)}>+</Button>
                        )}
                      </TableCell>
                  );
                  } if (source === 'Mobile sources') {
                    const key = `mobilesources_${company.subsidiary_name}_${month}_${reportingPeriod}`;
                    const dataEntered = superStatmobilecomb[key] && superStatmobilecomb[key].length > 0;
                    const data = superStatmobilecomb[key] || [];
                    
                    // console.log("dataEntered",superStatmobilecomb[key])
                    return (
                      <TableCell key={month}>
                        {dataEntered ? (
                          <div>
                          {(() => {
                            const totalEqco2 = data.reduce((total, item) => {
                              const itemTotal = item.data.emissions.reduce((itemSum, emission) => {
                                return itemSum + (emission.eqco2 || 0);
                              }, 0);
                              return total + itemTotal;
                            }, 0);
                            return <span>{totalEqco2.toFixed(2)}</span>; // Display the total eqco2
                          })()}
                          <Button onClick={() => handleOpenDialog(month, source)}>EDIT</Button>
                        </div>
                          
                        ) : (
                          <Button onClick={() => handleOpenDialog(month, source)}>+</Button>
                        )}
                      </TableCell>
                    );
                  } 
                  else {
                    // For other sources, always show "+"
                    return (
                      <TableCell key={month}>
                        <Button onClick={() => handleOpenDialog(month, source)}>+</Button>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      {currentSource === 'Stationary Combustion' && (
       
        <StationaryCombustionDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitData}
        formData={formData}
        handleInputChange={handleInputChange}
        source={currentSource}
        month={currentMonth}
        company={company.subsidiary_name}
        reportingPeriod={reportingPeriod}
      />
      )}
      {currentSource === 'Mobile sources' && (
        <MobileResourceDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmitData}
          formData={formData}
          handleInputChange={handleInputChange}
          source={currentSource}
          month={currentMonth}
          company={company.subsidiary_name}
          reportingPeriod={reportingPeriod}
        />
      )}
      {currentSource === 'Fugitive Emissions - Refrigeration' && (
        <FugitiveDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmitData}
          formData={formData}
          handleInputChange={handleInputChange}
          source={currentSource}
          month={currentMonth}
          company={company.subsidiary_name}
          reportingPeriod={reportingPeriod}
        />
      )}
      {currentSource === 'Purchased Electricity' && (
        <PurchasedElectricityDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmitData}
          formData={formData}
          handleInputChange={handleInputChange}
          source={currentSource}
          month={currentMonth}
          company={company.subsidiary_name}
          reportingPeriod={reportingPeriod}
        />
      )}
      {currentSource === 'Purchase steam, heat and cooling' && (
        <PurchasedSteamHeatingCoolingDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onSubmit={handleSubmitData}
          formData={formData}
          handleInputChange={handleInputChange}
          source={currentSource}
          month={currentMonth}
          company={company.subsidiary_name}
          reportingPeriod={reportingPeriod}
        />
      )}
      {/* Add more conditional renders for other sources */}
    </div>
    </Box>
  );
};

export default MonthlyDataEntry;

