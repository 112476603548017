import React, { useState, useEffect, useContext } from 'react';
import {
    TableContainer,Paper, Drawer, Box, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, IconButton, Tabs, Tab, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material';
import { ArrowDownward, Delete } from '@mui/icons-material';
import { SourceContext } from '../contexts/Sourcecontext';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios';
import { Switch } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { convertLegacyOperators } from '@mui/x-data-grid/internals';
import Steam_logo from './steam.png'
const PurchasedSteamHeatingCoolingDialog = ({ open, onClose, onSubmit, source, month, year, company, reportingPeriod }) => {
    
    
    const initialFormData = {
        SHCfacilityDescription: '',
        SHCfacilityArea: '',
        steamsources: [{ fuelCombusted: '', boilerefficiency: '', steampurchased: '' }],
        coolingElec: '',
        coolingEff: '',
        heatingElec: '',
        heatingEff: ''
      };

  const { parentCompany, SHCfacilities = [], setSHCFacilities } = useContext(SourceContext);
  const { superStatcombusstate, separatedFacilitiesT, fetchAndProcessData } = useContext(SourceContext);
  const [currentTab, setCurrentTab] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const [dialogData, setDialogData] = useState(null);
  // const [separatedFacilitiesT, setSeparatedFacilitiesT] = useState({});
  // const { superStatcombusstate, setsuperStatcombusstate } = useContext(SourceContext);
  const [showCoolingFields, setShowCoolingFields] = useState(false);
  const [showHeatingFields, setShowHeatingFields] = useState(false);
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };


  useEffect(() => {
    if (open) {
      fetchAndProcessData();
    }
  }, []);

  useEffect(() => {
    const key = `purchasedsteamheatingcooling_${company}_${month}_${reportingPeriod}`;
    setDialogData(separatedFacilitiesT[key] || []);
  }, [separatedFacilitiesT, company, month, reportingPeriod]);

  useEffect(() => {
    const key = `purchasedsteamheatingcooling_${company}_${month}_${reportingPeriod}`;
    const dataForKey = separatedFacilitiesT[key] || [];
    setDialogData(dataForKey);

    setSHCFacilities(dataForKey.length > 0 ? dataForKey : [{
      id: 1,
      data: {
        SHCfacilityDescription: '',
        SHCfacilityArea: '',
        steamsources: [{ fuelCombusted: '', boilerefficiency: '', steampurchased: '' }],
        coolingElec: '',
        coolingEff: '',
        heatingElec: '',
        heatingEff: ''
      }
    }]); // Reset to empty array if no data
  }, [separatedFacilitiesT]);


//   const handleChange = (e, facilityIndex, fuelIndex = null) => {
//     const { name, value } = e.target;
//     const updatedFacilities = SHCfacilities.map((SHCfacility, index) => {
//       if (index === facilityIndex) {
//         if (fuelIndex !== null) {
//           const updatedFuels = SHCfacility.data.fuels.map((fuel, idx) => {
//             if (idx === fuelIndex) {
//               return { ...fuel, [name]: value };
//             }
//             return fuel;
//           });
//           return { ...SHCfacility, data: { ...SHCfacility.data, fuels: updatedFuels } };
//         }
//         return { ...SHCfacility, data: { ...SHCfacility.data, [name]: value } };
//       }
//       return SHCfacility;
//     });
//     setSHCFacilities(updatedFacilities);
//   };



  const handleChange = (e, facilityIndex, steamSourceIndex = null) => {
    const { name, value } = e.target;
    
    const updatedFacilities = SHCfacilities.map((SHCfacility, index) => {
      if (index === facilityIndex) {
        if (steamSourceIndex !== null) {
          // If changing within steamsource array
          const updatedSteamSource = SHCfacility.data.steamsources.map((steamsource, idx) => {
            if (idx === steamSourceIndex) {
              return { ...steamsource, [name]: value }; // Update specific steamsource entry
            }
            return steamsource;
          });
          return { ...SHCfacility, data: { ...SHCfacility.data, steamsources: updatedSteamSource } };
        } else {
          // Handle changes to other fields outside the steamsource array
          return { ...SHCfacility, data: { ...SHCfacility.data, [name]: value } };
        }
      }
      return SHCfacility;
    });
  
    setSHCFacilities(updatedFacilities);
  };

  const addSteamSource = (facilityIndex) => {
    const updatedFacilities = SHCfacilities.map((SHCfacility, index) => {
      if (index === facilityIndex) {
        return {
          ...SHCfacility,
          data: { ...SHCfacility.data, steamsources: [...SHCfacility.data.steamsources, { fuelCombusted: '', boilerefficiency: '', steampurchased: '' }] }
        };
      }
      return SHCfacility;
    });
    setSHCFacilities(updatedFacilities);
  };

  const deleteSteamSource = (facilityIndex, steamsourceIndex) => {
    const updatedFacilities = SHCfacilities.map((SHCfacility, index) => {
      if (index === facilityIndex) {
        const updatedSteamsources = SHCfacility.data.steamsources.filter((_, idx) => idx !== steamsourceIndex);
        return { ...SHCfacility, data: { ...SHCfacility.data, steamsources: updatedSteamsources } };
      }
      return SHCfacility;
    });
    setSHCFacilities(updatedFacilities);
  };

  
  const addFacility = () => {
    // Generate a new SHCfacility ID, ensure it's a string
    const newFacilityId = SHCfacilities.length > 0 
      ? (Math.max(...SHCfacilities.map(f => parseInt(f.id, 10))) + 1).toString() 
      : "1";
    
    setSHCFacilities([...SHCfacilities, { id: newFacilityId, data: initialFormData }]);
    setCurrentTab(SHCfacilities.length); // Switch to the new SHCfacility tab
  };

  const deleteFacility = (facilityId) => {
    const updatedFacilities = SHCfacilities.filter(SHCfacility => SHCfacility.id !== facilityId);
    setSHCFacilities(updatedFacilities);
    if (currentTab >= updatedFacilities.length) {
      setCurrentTab(updatedFacilities.length - 1);
    }
  };
console.log("SHCfacilities sending back",SHCfacilities)
  const handleSubmit = async() => {
    onSubmit(SHCfacilities.map(SHCfacility => ({ ...SHCfacility.data, month, source })));
    const token = await getAccessTokenSilently();
    try {
      const response = await axios.post('http://localhost:5000/api/stationarycombustion', {
        parentcompany: parentCompany.name,
        parentcompany_country: parentCompany.country,
        company: company,
        period: reportingPeriod,
        month: month, 
        SHCfacilities: SHCfacilities,
      },{
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error adding company:', error.response || error.message);
    }
    onClose();
  };
  const fuelOptionsByCountry = {
    India: [
      'Anthracite Coal', 'Aviation gasoline', 'Biodiesel', 'Biogasoline',
      'Biomass municipal and industrial material (RCPHE)', 'Bitumin',
      'Bituminous coal', 'Blast furnace gas', 'Charcoal', 'Coal briquettes',
      'Coal coke', 'Coal tar', 'Coke oven gas', 'Coking coal', 'Crude oil',
      'Diesel oil', 'Ethane', 'Gas coke', 'Gas works gas', 'Jet gasoline',
      'Jet kerosene', 'Landfill biogas that is captured for combustion (methane only)',
      'Lignite', 'Lignite coke', 'Liquefied petroleum gas', 'Lubricants',
      'Motor gasoline', 'Municipal solid waste', 'Municipal wastes (Biomass fraction)',
      'Naptha', 'Natural Gas', 'Oil shale and tar sands', 'Orimulsion',
      'Other biogas that is captured for combustion (methane only)', 'Other kerosene',
      'Other liquid biofuels', 'Other natural gas liquids', 'Other petroleum based products',
      'Other primary solid biomass fuels', 'Oxygen steel furnace gas', 'Paraffin waxes',
      'Patent fuel', 'Peat', 'Petroleum coke', 'Refinery feedstocks',
      'Refinery gas and liquids', 'Residual Fuel Oil No. 6', 'Shale oil',
      'Sludge biogas that is captured for combustion (methane only)', 'Solvents if mineral turpentine or white spirits',
      'Sub-bituminous coal', 'Sulphite lyes', 'Waste oils', 'Wood or Wood waste'
    ],
    USA : [
      'Anthracite Coal', 'Bituminous Coal', 'Sub-bituminous Coal', 'Lignite Coal', 'Mixed (Commercial Sector)',
      'Mixed (Electric Power Sector)', 'Mixed (Industrial Coking)', 'Mixed (Industrial Sector)', 'Coal Coke', 'Municipal Solid Waste',
      'Petroleum Coke', 'Plastics', 'Tires', 'Agricultural Byproducts', 'Peat', 'Solid Byproducts', 'Wood and Wood Residuals', 'Natural Gas',
      'Blast Furnace Gas', 'Coke Oven Gas',
      'Fuel Gas', 'Propane Gas', 'Landfill Gas', 'Other Biomass Gases', 'Asphalt and Road Oil', 'Aviation Gasoline', 'Butane', 'Butylene',
      'Crude Oil', 'Distillate Fuel Oil No. 1',
      'Distillate Fuel Oil No. 2', 'Distillate Fuel Oil No. 4', 'Ethane', 'Ethylene', 'Heavy Gas Oils', 'Isobutane', 'Isobutylene', 'Kerosene',
      'Kerosene-Type Jet Fuel', 'Liquefied Petroleum Gases (LPG)',
      'Lubricants', 'Motor Gasoline', 'Naphtha (<401 deg F)', 'Natural Gasoline', 'Other Oil (>401 deg F)', 'Pentanes Plus', 'Petrochemical Feedstocks',
      'Propane', 'Propylene', 'Residual Fuel Oil No. 5',
      'Residual Fuel Oil No. 6', 'Special Naphtha', 'Unfinished Oils', 'Used Oil', 'Biodiesel (100%)', 'Ethanol (100%)', 'Rendered Animal Fat', 'Vegetable Oil'
  ],
  Australia: [
        'Bituminous Coal', 'Sub-bituminous Coal', 'Anthracite', 'Brown Coal (Lignite)', 'Coking Coal',
        'Coal Briquettes', 'Coal Coke', 'Coal Tar', 'Other Solid Fossil Fuels', 'Recycled Industrial Materials (fossil-derived)',
        'Recycled Passenger Car Tyres', 'Recycled Truck and Off-road Tyres', 'Non-biomass Municipal Materials', 'Dry Wood',
        'Green and Air Dried Wood', 'Sulphite Lyes', 'Bagasse', 'Biomass (Municipal and Industrial)', 'Charcoal', 'Primary Solid Biomass Fuels',
        'Natural Gas', 'Coal Seam Methane', 'Coal Mine Waste Gas', 'Compressed Natural Gas', 'Unprocessed Natural Gas', 'Ethane',
        'Coke Oven Gas', 'Blast Furnace Gas', 'Town Gas', 'Liquefied Natural Gas',
        'Other Gaseous Fossil Fuels', 'Landfill Biogas (Methane only)', 'Sludge Biogas (Methane only)', 'Other Captured Biogas', 'Biomethane'
    ],

  NewZealand:[
      "general_Coal - Default", "general_Coal - Bituminous","general_Coal - Sub-Bituminous",
      "general_Coal - Lignite",
      "commercial_Coal - Default","commercial_Coal - Bituminous",
      "commercial_Coal - Sub-Bituminous","commercial_Coal - Lignite",
      "commercial_Diesel","commercial_LPG", "commercial_Heavy Fuel Oil","commercial_Light Fuel Oil","commercial_Natural Gas",
      "industrial_Coal - Default","industrial_Coal - Bituminous","industrial_Coal - Sub-Bituminous","industrial_Coal - Lignite",
      "industrial_Diesel","industrial_LPG","industrial_Heavy Fuel Oil","industrial_Light Fuel Oil", "industrial_Natural Gas"
  ]
    // Add more countries and their respective fuel options here
  };

  const unitsByState = {
    Solid: ['metric ton','kg','pounds','TJ','GJ','MJ','KWh','mmBTU', 'Therm'],
    Liquid: ['liters (L)','barrel(bbl)','gallon','TJ','GJ','MJ','KWh','mmBTU', 'Therm'],
    Gas: ['cubic meters (m3)','foot3','TJ','GJ','MJ','KWh','mmBTU', 'Therm'],
  };
  
  const country = parentCompany.country;
  const fuelOptions = fuelOptionsByCountry[country] || [];

  const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

//     const handleUnitChange = (e, facilityIndex, fuelIndex) => {
//       const { value } = e.target;
//       const updatedFacilities = SHCfacilities.map((SHCfacility, index) => {
//           if (index === facilityIndex) {
//               const updatedFuels = SHCfacility.data.fuels.map((fuel, idx) => {
//                   if (idx === fuelIndex) {
//                       return { ...fuel, units: value };
//                   }
//                   return fuel;
//               });
//               return { ...SHCfacility, data: { ...SHCfacility.data, fuels: updatedFuels } };
//           }
//           return SHCfacility;
//       });
//       setSHCFacilities(updatedFacilities);
//   };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        sx={{
          width: '40vw',
          padding: 2,
          height: '100%',
        }}
      >
        <IconButton
          sx={{ position: 'absolute', top: '8px', right: '8px' }}
          onClick={onClose}
        >
          <ArrowDownward />
        </IconButton>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="SHCfacility tabs">
          {SHCfacilities.map((SHCfacility, index) => (
            <Tab key={SHCfacility.id} label={`Facility ${SHCfacility.id}`} />
          ))}
          <Tab label="Add Facility" onClick={addFacility} />
          <Tab label="Submit Data" />
        </Tabs>
        {currentTab < SHCfacilities.length ? (
          <Box p={2}>
            <Typography>
              Purchased Steam, Heating and Colling
              </Typography>
             <Box display="flex" justifyContent="space-between" alignItems="center">
             
            <Typography variant="h6" gutterBottom>
            
              Enter Data for Facility {SHCfacilities[currentTab].id}
              <DialogTitle>Enter Data for {source}</DialogTitle>
         <Typography>Company: {company}</Typography>
         <Typography>Reporting Period: {reportingPeriod}</Typography>
         <Typography>Month and Year: {month}</Typography>
         {/* <Typography>Year: {year}</Typography> */}
            </Typography>
            <img src={Steam_logo} alt="Logo" style={{ height: '170px', marginLeft: '6px'}} />
             </Box>
            <Typography variant="subtitle1" gutterBottom>
              {month} {year}
            </Typography>
            <TextField
              label="Facility Description"
              name="SHCfacilityDescription"
              value={SHCfacilities[currentTab].data.SHCfacilityDescription}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Facility Area"
              name="SHCfacilityArea"
              value={SHCfacilities[currentTab].data.SHCfacilityArea}
              onChange={(e) => handleChange(e, currentTab)}
              fullWidth
              margin="normal"
            />
            <Typography variant="h6" gutterBottom>
              Purchased Steam Details
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Fuel Combusted</TableCell>
                  <TableCell>Boiler Efficiency</TableCell>
                  <TableCell>Steam Purchased</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SHCfacilities[currentTab] && SHCfacilities[currentTab].data.steamsources.map((steamsource, steamSourceIndex) => (
                  <TableRow key={steamSourceIndex}>
                    <TableCell>
                      <FormControl fullWidth>
                      <InputLabel htmlFor="device-dropdown">Steam Sources</InputLabel>
                      <Select
                          name="fuelCombusted"
                          value={steamsource.fuelCombusted}
                          onChange={(e) => handleChange(e, currentTab, steamSourceIndex)}
                          MenuProps={{ PaperProps: { style: { maxHeight: 300, width: 250 } } }}
                          style={{
                              width: '100%',
                              marginTop: '10px',
                              fontSize: '16px',
                              borderRadius: '4px', // Add border radius for a smoother look
                              borderColor: '#ced4da', // Add border color
                              backgroundColor: '#fff', // Set background color
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Add box shadow for depth
                          }}
                          inputProps={{
                              style: { fontSize: '16px' }, // Set font size for consistency
                          }}
                      >
                          {fuelOptions.map((fuel) => (
                              <MenuItem key={fuel} style={{  marginRight: '150px', fontSize: '16px' }} value={fuel}>
                                  {fuel}
                              </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Boiler Efficiency"
                        name="boilerefficiency"
                        value={steamsource.boilerefficiency}
                        onChange={(e) => handleChange(e, currentTab,steamSourceIndex)}
                        fullWidth
                        margin="normal"
                        />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Steam Purchased"
                        name="steampurchased"
                        value={steamsource.steampurchased}
                        onChange={(e) => handleChange(e, currentTab, steamSourceIndex)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => deleteSteamSource(currentTab, steamSourceIndex)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button variant="contained" color="primary" onClick={() => addSteamSource(currentTab)}>
              Add More Steam Sources
            </Button>
             {/* Switch for Cooling Data */}
      <Box mt={2}>
        <Switch
          checked={showCoolingFields}
          onChange={() => setShowCoolingFields(!showCoolingFields)}
        />
        <span>Enter Cooling Data</span>
      </Box>

      {/* Conditionally render cooling fields */}
      {showCoolingFields && (
        <Box mt={2}>
          <TextField
            label="Cooling Electricity"
            name="coolingElec"
            value={SHCfacilities[currentTab]?.data.coolingElec || ''}
            onChange={(e) => handleChange(e, currentTab)}
            fullWidth
          />
          <TextField
            label="Cooling Efficiency"
            name="coolingEff"
            value={SHCfacilities[currentTab]?.data.coolingEff || ''}
            onChange={(e) => handleChange(e, currentTab)}
            fullWidth
          />
        </Box>
      )}

      {/* Switch for Heating Data */}
      <Box mt={2}>
        <Switch
          checked={showHeatingFields}
          onChange={() => setShowHeatingFields(!showHeatingFields)}
        />
        <span>Enter Heating Data</span>
      </Box>

      {/* Conditionally render heating fields */}
      {showHeatingFields && (
        <Box mt={2}>
          <TextField
            label="Heating Electricity"
            name="heatingElec"
            value={SHCfacilities[currentTab]?.data.heatingElec || ''}
            onChange={(e) => handleChange(e, currentTab)}
            fullWidth
          />
          <TextField
            label="Heating Efficiency"
            name="heatingEff"
            value={SHCfacilities[currentTab]?.data.heatingEff || ''}
            onChange={(e) => handleChange(e, currentTab)}
            fullWidth
          />
        </Box>
      )}
    </Box>
        //   </Box>
        ) : 
        (
        //   currentTab > SHCfacilities.length && (
        //     <Box p={2}>
        //       <Typography variant="h6" gutterBottom>
        //         Summary
        //       </Typography>
        //       {SHCfacilities.map((SHCfacility) => (
        //         <Box key={SHCfacility.id} mb={2} display="flex" alignItems="center">
        //           <Box flex={1}>
        //             <Typography variant="subtitle1">
        //               Facility {SHCfacility.id}:
        //             </Typography>
        //             <Typography>Parent Company: {parentCompany.name}</Typography>
        //             <Typography>Facility Description: {SHCfacility.data.SHCfacilityDescription}</Typography>
        //             <Typography>Facility Area: {SHCfacility.data.SHCfacilityArea}</Typography>
        //             <Typography>Steam Purchases:</Typography>
        //             {SHCfacility.data.steamsources.map((steamsource, idx) => (
        //               <Box key={idx} ml={2}>
        //                 <Typography>Fuel Combusted: {steamsource.fuelCombusted}</Typography>
        //                 <Typography>Boiler Efficiency: {steamsource.boilerefficiency}</Typography>
        //                 <Typography>Steam Purchased: {steamsource.steampurchased}</Typography>
                        
        //               </Box>
        //             ))}
        //           </Box>
        //           {SHCfacilities.length > 1 && (
        //           <IconButton onClick={() => deleteFacility(SHCfacility.id)}>
        //             <Delete />
        //           </IconButton>)}
        //         </Box>
        //       ))}
        //       <Box mt={2} display="flex" justifyContent="space-between">
        //         <Button variant="contained" color="primary" onClick={handleSubmit}>
        //           Submit All Data
        //         </Button>
        //         <Button variant="contained" color="secondary" onClick={onClose}>
        //           Cancel
        //         </Button>
        //       </Box>
        //     </Box>
        //   )
        // )
        
        currentTab >= SHCfacilities.length && (
            <Box p={2}>
              <Typography variant="h6" gutterBottom>
                Summary
              </Typography>
              {SHCfacilities.map((SHCfacility) => (
                <Box key={SHCfacility.id} mb={2} display="flex" alignItems="center">
                  <Box flex={1}>
                    <Typography variant="subtitle1">
                      Electricity Facility {SHCfacility.id}:
                    </Typography>
                    <Typography>Parent Company: {parentCompany.name}</Typography>
                    <Typography>Facility Description: {SHCfacility.data.SHCfacilityDescription}</Typography>
                    <Typography>Facility Area: {SHCfacility.data.SHCfacilityArea}</Typography>
                    <Typography>Steam Purchases:</Typography>
          
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Fuel Combusted</TableCell>
                            <TableCell>Boiler Efficiency</TableCell>
                            <TableCell>Steam Purchased</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {SHCfacility.data.steamsources.map((steamsource, idx) => (
                            <TableRow key={idx}>
                              <TableCell>{steamsource.fuelCombusted}</TableCell>
                              <TableCell>{steamsource.boilerefficiency}</TableCell>
                              <TableCell>{steamsource.steampurchased}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
          
                    {/* Renewable Electricity section
                    <Typography variant="subtitle1" mt={2}>
                      Renewable Electricity Data:
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>City</TableCell>
                            <TableCell>KWh</TableCell>
                            <TableCell>Emission Factor</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{elecfacility.data.RenewableElec.city}</TableCell>
                            <TableCell>{elecfacility.data.RenewableElec.KWh}</TableCell>
                            <TableCell>{elecfacility.data.RenewableElec.emFactor}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer> */}
                  </Box>
                  {SHCfacilities.length > 1 && (
                <IconButton onClick={() => deleteFacility(SHCfacility.id)}>
                  <Delete />
                </IconButton>)}
                </Box>
              ))}
            </Box>
             
          )  
      )
        
        }
      </Box>
    </Drawer>
  );
};

export default PurchasedSteamHeatingCoolingDialog;

