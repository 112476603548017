// //this is diplaying the reporting periods and the companies within and the sources selected for each
// import React, { useContext } from 'react';
// import { SourceContext } from '../contexts/Sourcecontext';
// import CompanySourcesTable from './CompanySourcesTable';

// const ReportingPeriodSources = () => {
//   const { companies, sourceSelection, reportingPeriods } = useContext(SourceContext);

//   return (
//     <div>
//       {reportingPeriods.map((period) => (
//         <div key={period}>
//           <h2>Reporting Period: {period}</h2>
//           <CompanySourcesTable reportingPeriod={period} companies={companies} sourceSelection={sourceSelection} />
//         </div>
//       ))}
//     </div>
//   );
// };

// export default ReportingPeriodSources;

import React, { useContext } from 'react';
import { SourceContext } from '../contexts/Sourcecontext';
import CompanySourcesTable from './CompanySourcesTable';

const ReportingPeriodSources = ({ selectedReportingPeriod }) => {
  const { companies, sourceSelection } = useContext(SourceContext);

  return (
    <div>
      <h2>Reporting Period: {selectedReportingPeriod}</h2>
      <CompanySourcesTable
        reportingPeriod={selectedReportingPeriod}
        companies={companies}
        sourceSelection={sourceSelection}
      />
    </div>
  );
};

export default ReportingPeriodSources;

